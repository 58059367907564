import axios from "../../axios-base";

export const GET_APPLICATIONS_SUCCESS = "GET_APPLICATIONS_SUCCESS";
export const APPLICATON_INITIALIZATION = "APPLICATON_INITIALIZATION";
export const GET_ONE_APPLICATIONS_SUCCESS = "GET_ONE_APPLICATIONS_SUCCESS";
export const GET_ONE_ACTIVE_APPLICATIONS_SUCCESS =
  "GET_ONE_ACTIVE_APPLICATIONS_SUCCESS";
export const GET_APPLICATIONS_START = "GET_APPLICATIONS_START";
export const GET_APPLICATIONS_FAIL = "GET_APPLICATIONS_FAIL";

export const GET_ONE_APPLICATIONS_BY_TIN_SUCCESS = "GET_ONE_APPLICATIONS_BY_TIN_SUCCESS";
export const GET_APPLICATIONS_BY_TIN_START = "GET_APPLICATIONS_BY_TIN_START";
export const GET_APPLICATIONS_BY_TIN_FAIL = "GET_APPLICATIONS__BY_TIN_FAIL";

export const GET_ONE_APPLICATIONS_BY_TEL_SUCCESS = "GET_ONE_APPLICATIONS_BY_TEL_SUCCESS";
export const GET_APPLICATIONS_BY_TEL_START = "GET_APPLICATIONS_BY_TEL_START";
export const GET_APPLICATIONS_BY_TEL_FAIL = "GET_APPLICATIONS__BY_TEL_FAIL";

export const GET_ONE_APPLICATIONS_BY_NID_SUCCESS = "GET_ONE_APPLICATIONS_BY_NID_SUCCESS";
export const GET_APPLICATIONS_BY_NID_START = "GET_APPLICATIONS_BY_NID_START";
export const GET_APPLICATIONS_BY_NID_FAIL = "GET_APPLICATIONS__BY_NID_FAIL";

export const GET_ONE_APPLICATIONS_BY_EMAIL_SUCCESS = "GET_ONE_APPLICATIONS_BY_EMAIL_SUCCESS";
export const GET_APPLICATIONS_BY_EMAIL_START = "GET_APPLICATIONS_BY_EMAIL_START";
export const GET_APPLICATIONS_BY_EMAIL_FAIL = "GET_APPLICATIONS__BY_EMAIL_FAIL";

export const GET_APPLICATIONS_BY_SEARCH_SUCCESS = "GET_APPLICATIONS_BY_SEARCH_SUCCESS";
export const GET_ONE_APPLICATIONS_BY_SEARCH_SUCCESS = "GET_ONE_APPLICATIONS_BY_SEARCH_SUCCESS";
export const GET_APPLICATIONS_BY_SEARCH_START = "GET_APPLICATIONS_BY_SEARCH_START";
export const GET_APPLICATIONS_BY_SEARCH_FAIL = "GET_APPLICATIONS_BY_SEARCH_FAIL";

export const GET_OLD_APPLICANTS_SUCCESS = "GET_OLD_APPLICANTS_SUCCESS";
export const GET_ONE_OLD_APPLICANTS_SUCCESS = "GET_ONE_OLD_APPLICANTS_SUCCESS";
export const GET_OLD_APPLICANTS_START = "GET_OLD_APPLICANTS_START";
export const GET_OLD_APPLICANTS_FAIL = "GET_OLD_APPLICANTS_FAIL";

export const getApplicationsSuccess = (data) => {
  return {
    type: GET_APPLICATIONS_SUCCESS,
    payload: data,
  };
};

export const getApplicationsSuccesInit = (data) => {
  return {
    type: GET_ONE_APPLICATIONS_SUCCESS,
    payload: data,
  };
};

export const getApplicationCriteriasSuccesInit = (data) =>{
  // return {
  //   type: GET_ONE_APPLICATIONS_SUCCESS,
  //   payload: data,
  // };
}

export const applicationInitialization = () =>{
  return {
    type: APPLICATON_INITIALIZATION,
  };
}

export const getOneApplicationsSuccess = (data) => {
  return {
    type: GET_ONE_APPLICATIONS_SUCCESS,
    payload: data,
  };
};

export const getApplicationsStart = () => {
  return {
    type: GET_APPLICATIONS_START,
  };
};

export const getApplicationsFail = (error) => {
  const err = error?.response?.data?.message;
  return {
    type: GET_APPLICATIONS_FAIL,
    payload: err,
  };
};

export const getApplications = (lang) => {
  return (dispatch) => {
    dispatch(getApplicationsStart());
    axios
      .get(`/applications`)
      .then((response) => {
        dispatch(getApplicationsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getApplicationsFail(err));
      });
  };
};

export const getApplicationsReport = (object) => {
  return (dispatch) => {
    dispatch(getApplicationsStart());
    axios
      .post(`/applications/reports`, object)
      .then((response) => {
        dispatch(getApplicationsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getApplicationsFail(err));
      });
  };
};

export const createApplications = (object) => {
  return (dispatch) => {
    dispatch(getApplicationsStart());
    axios
      .post(`/applications/create`, object)
      .then((response) => {
        dispatch(getOneApplicationsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getApplicationsFail(err));
      });
  };
};

export const getOneApplications = (token, id) => {
  return (dispatch) => {
    dispatch(getApplicationsStart());
    axios
      .get(`/applications/find/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneApplicationsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getApplicationsFail(err));
      });
  };
};

export const getOneApplicationsByCode = (id,tel) => {
  return (dispatch) => {
    dispatch(getApplicationsStart());
    axios
      .get(`/selected-apps/by-code/${id}/${tel}`)
      .then((response) => {
        dispatch(getOneApplicationsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getApplicationsFail(err));
      });
  };
};

export const activatingApplications = (token, id) => {
  return (dispatch) => {
    dispatch(getApplicationsStart());
    axios
      .put(`/applications/activating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneApplicationsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getApplicationsFail(err));
      });
  };
};

export const desActivatingApplications = (token, id) => {
  return (dispatch) => {
    dispatch(getApplicationsStart());
    axios
      .put(`/applications/desactivating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneApplicationsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getApplicationsFail(err));
      });
  };
};

export const updateApplications = (token, object) => {
  return (dispatch) => {
    dispatch(getApplicationsStart());
    axios
      .put(`/applications/edit/${object?.id}`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneApplicationsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getApplicationsFail(err));
      });
  };
};

export const getOneApplicationsByTinSuccess = (data) => {
    return {
      type: GET_ONE_APPLICATIONS_BY_TIN_SUCCESS,
      payload: data,
    };
  };
  
  export const getOneApplicationsByTinStart = () => {
    return {
      type: GET_APPLICATIONS_BY_TIN_START,
    };
  };
  export const getApplicationsByTinFail = (error) => {
    return {
      type: GET_APPLICATIONS_BY_TIN_FAIL,
      payload: error,
    };
  };

  export const getApplicantByTin = (id) => {
    return (dispatch) => {
      dispatch(getOneApplicationsByTinStart());
      axios
        .get(`/applications/by-tin/${id}`)
        .then((response) => {
          dispatch(getOneApplicationsByTinSuccess(response.data.results));
        })
        .catch((err) => {
          dispatch(getApplicationsByTinFail(err));
        });
    };
  };

  export const getOneApplicationsByTelSuccess = (data) => {
    return {
      type: GET_ONE_APPLICATIONS_BY_TEL_SUCCESS,
      payload: data,
    };
  };
  
  export const getOneApplicationsByTelStart = () => {
    return {
      type: GET_APPLICATIONS_BY_TEL_START,
    };
  };
  export const getApplicationsByTelFail = (error) => {
    return {
      type: GET_APPLICATIONS_BY_TEL_FAIL,
      payload: error,
    };
  };

  export const getApplicantByTelephone = (id) => {
    return (dispatch) => {
      dispatch(getOneApplicationsByTelStart());
      axios
        .get(`/applications/by-tel/${id}`)
        .then((response) => {
          dispatch(getOneApplicationsByTelSuccess(response.data.results));
        })
        .catch((err) => {
          dispatch(getApplicationsByTelFail(err));
        });
    };
  };

  export const getOneApplicationsByNidSuccess = (data) => {
    return {
      type: GET_ONE_APPLICATIONS_BY_NID_SUCCESS,
      payload: data,
    };
  };
  
  export const getOneApplicationsByNIDStart = () => {
    return {
      type: GET_APPLICATIONS_BY_NID_START,
    };
  };
  export const getApplicationsByNidFail = (error) => {
    return {
      type: GET_APPLICATIONS_BY_NID_FAIL,
      payload: error,
    };
  };

  export const getApplicantByNationalId = (id) => {
    return (dispatch) => {
      dispatch(getOneApplicationsByNIDStart());
      axios
        .get(`/applications/by-nid/${id}`)
        .then((response) => {
          dispatch(getOneApplicationsByNidSuccess(response.data.results));
        })
        .catch((err) => {
          dispatch(getApplicationsByNidFail(err));
        });
    };
  };

  export const getOneApplicationsByEmailSuccess = (data) => {
    return {
      type: GET_ONE_APPLICATIONS_BY_EMAIL_SUCCESS,
      payload: data,
    };
  };
  
  export const getOneApplicationsByEmailStart = () => {
    return {
      type: GET_APPLICATIONS_BY_EMAIL_START,
    };
  };
  export const getApplicationsByEmailFail = (error) => {
    return {
      type: GET_APPLICATIONS_BY_EMAIL_FAIL,
      payload: error,
    };
  };

  export const getApplicantByEmail = (id) => {
    return (dispatch) => {
      dispatch(getOneApplicationsByEmailStart());
      axios
        .get(`/applications/by-email/${id}`)
        .then((response) => {
          dispatch(getOneApplicationsByEmailSuccess(response.data.results));
        })
        .catch((err) => {
          dispatch(getApplicationsByEmailFail(err));
        });
    };
  };

  export const getApplicationsBySearching = (searchkey, searchvalue) => {
    return (dispatch) => {
      dispatch(getApplicationsStart());
      axios
        .get(`/applications/search/${searchkey}/${searchvalue}`)
        .then((response) => {
          dispatch(getApplicationsSuccess(response.data.results));
        })
        .catch((err) => {
          dispatch(getApplicationsFail(err));
        });
    };
  };

  export const sendLetters = () => {
    return (dispatch) => {
      dispatch(getApplicationsStart());
      axios
        .get(`/applications/send-sms/pre-selection`)
        .then((response) => {
          dispatch(getApplicationsSuccess(response.data.results));
        })
        .catch((err) => {
          dispatch(getApplicationsFail(err));
        });
    };
  };

  export const sendSMSToApplicantsInIC = (code) => {
    return (dispatch) => {
      dispatch(getApplicationsStart());
      axios
        .get(`/selected-apps/send-ic-decision/${code}`)
        .then((response) => {
          dispatch(getApplicationsSuccess(response.data.results));
        })
        .catch((err) => {
          dispatch(getApplicationsFail(err));
        });
    };
  };

  export const getOldApplicantsSuccess = (data) => {
    return {
      type: GET_OLD_APPLICANTS_SUCCESS,
      payload: data,
    };
  };
  
  export const getOneOldApplicantsSuccess = (data) => {
    return {
      type: GET_ONE_OLD_APPLICANTS_SUCCESS,
      payload: data,
    };
  };
  
  export const getOldApplicantsStart = () => {
    return {
      type: GET_OLD_APPLICANTS_START,
    };
  };
  export const getOldApplicantsFail = (error) => {
    return {
      type: GET_OLD_APPLICANTS_FAIL,
      payload: error,
    };
  };
  
  export const findOneOldApplicants = (id) => {
    return (dispatch) => {
      dispatch(getOldApplicantsStart());
      axios
        .get(`/old-applicants/find-by-id/${id}`)
        .then((response) => {
          dispatch(getOneOldApplicantsSuccess(response.data.results));
        })
        .catch((err) => {
          dispatch(getOldApplicantsFail(err));
        });
    };
  };

  export const approveApplicantsInIC = (codes) => {
    return (dispatch) => {
      dispatch(getApplicationsStart());
      axios
        .put(`/selected-apps/approve-applicants`, codes)
        .then((response) => {
          dispatch(getApplicationsSuccess(response.data.results));
        })
        .catch((err) => {
          dispatch(getApplicationsFail(err));
        });
    };
  };

  export const approveApplicantsInICondition = (codes) => {
    return (dispatch) => {
      dispatch(getApplicationsStart());
      axios
        .put(`/selected-apps/conditioned-approve-applicants`, codes)
        .then((response) => {
          dispatch(getApplicationsSuccess(response.data.results));
        })
        .catch((err) => {
          dispatch(getApplicationsFail(err));
        });
    };
  };

  export const rejectApplicantsInIC = (codes) => {
    return (dispatch) => {
      dispatch(getApplicationsStart());
      axios
        .put(`/selected-apps/reject-applicants`, codes)
        .then((response) => {
          dispatch(getApplicationsSuccess(response.data.results));
        })
        .catch((err) => {
          dispatch(getApplicationsFail(err));
        });
    };
  };
  

