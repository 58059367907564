import { useEffect, useState } from "react";
import Header from "../components/header";
import rab from "../rab.png";
import bdf from "../bdf.PNG";
import minagri from "../rwanda.png";
import {
  ActivateButton,
  InputEmail,
  InputNationalId,
  InputTelephone,
  InputText2,
  SelectInput,
  SubmitButton,
} from "../components/inputs";
import { Beadcrumb, CountDown, PageTitle } from "../components/utils";
import { useTranslation } from "react-i18next";
import constants from "../components/constants";
import { Provinces, Districts, Sectors } from "rwanda";
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  applicationInitialization,
  findOneOldApplicants,
  getApplicantByEmail,
  getApplicantByNationalId,
  getApplicantByTelephone,
  getApplicationsSuccesInit,
} from "../store/actions/applications";
import { successApplyingNotification } from "../components/notifications";
import { ReactNotifications } from "react-notifications-component";
// import { useDispatch, useSelector } from "react-redux";

function IntroductionPage() {
  const WORKSPACE = "IRIBURIRO/INTRODUCTION";
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(applicationInitialization());
  }, []);
  return (
    <>
      <Header />
      <div className="container">
        <Beadcrumb />
        <div className="pt-3">
          <CountDown />
        </div>
        <PageTitle pagetitle={WORKSPACE} />
        <div id="contact" className="input-data">
          <div className="" data-aos="fade-up">
            <div className="row pt-5">
              <div className="col-md-6">
                <h6>
                  Umushinga wo guteza imbere ubuhinzi bugamije ubucuruzi no
                  kugabanya ibibubangamira (CDAT)
                </h6>
                <p className="">
                  Ni umushinga watewe inkunga na Banki y’isi, ukaba ufite Intego
                  nyamukuru yo Kongera ikoreshwa ryo kuhira imyaka hagamijwe
                  umusaruro ndetse n’ubucuruzi bwawo ku bihingwa byatoranijwe no
                  kugeza imari ku baturage, amakoperative, n’ibigo (inganda nto
                  n’iziciriritse) bikora ubucuruzi mu by’ubuhinzi n’ubworozi.
                </p>
                <p className="the-bold">
                  Kwakira ibitekerezo by’imishinga bizamara iminsi 20 uhereye
                  taliki ya 18/07/2024 ukageza tariki 06/08/2024.
                </p>
              </div>
              <div className="col-md-6">
                <h6>
                  Commercialization and De-Risking for Agricultural
                  Transformation (CDAT) Project
                </h6>
                <p className="">
                  This a world Bank-funded project with the main objective of
                  increasing the use of irrigation and commercialization among
                  producers and agribusiness firms in supported value chains and
                  to increase access to agriculture finance.
                </p>
                <p className="the-bold">
                  The application submission will last 20 days from 18 July,
                  2024 to 06 August, 2024
                </p>
              </div>
            </div>
            <div className="row">
            <div className="col-md-6">
              <NavLink to="/terms-and-conditions">
                Read Terms and Conditons / Soma amategeko n'amabwiriza
              </NavLink>
            </div>
            <div className="col-md-4">
              <a
                className=""
                href="https://bdffiles.bdf.rw/site/publicationfiles/9819682d-bf3c-40fa-888c-6dc23942dfd6CDAT_BUSINESS_PLAN_TEMPLATE.pdf"
                target="_blank"
              >
                Soma imbata y'umushinga (Business Plan Template)
              </a>
            </div>
            </div>
            <div className="pt-5">
              <div className="row d-flex justify-content-center">
                <div className="col-md-2 d-flex justify-content-center">
                  <div>
                    <img alt="img" src={minagri} className="img-fluid h-25" />
                    <div className="text-center">
                      <span>MINAGRI</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 d-flex justify-content-center">
                  <img alt="img" src={rab} className="img-fluid h-25" />
                </div>
                <div className="col-md-2 text-center">
                  <img alt="img" src={bdf} className="h-25" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default IntroductionPage;
