import { useEffect, useState } from "react";
import Header from "../components/header";
import rab from "../rab.png";
import bdf from "../bdf.PNG";
import minagri from "../rwanda.png";
import {
  ActivateButton,
  InputEmail,
  InputNationalId,
  InputTelephone,
  InputText2,
  SelectInput,
  SubmitButton,
} from "../components/inputs";
import { Beadcrumb, CountDown, PageTitle } from "../components/utils";
import { useTranslation } from "react-i18next";
import constants from "../components/constants";
import { Provinces, Districts, Sectors } from "rwanda";
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  findOneOldApplicants,
  getApplicantByEmail,
  getApplicantByNationalId,
  getApplicantByTelephone,
  getApplicationsSuccesInit,
} from "../store/actions/applications";
import { successApplyingNotification } from "../components/notifications";
import { ReactNotifications } from "react-notifications-component";
// import { useDispatch, useSelector } from "react-redux";

function ApplicationDetails() {
  const WORKSPACE = "Ubusabe bwoherejwe neza / Application Successfully Sent";
  const application = useSelector((state) => state.applications.application);
  useEffect(() => {
    if (application?.uuid !== undefined && application?.uuid !== null) {
      successApplyingNotification();
    }
  }, [application]);



  return (
    <>
      <Header />
      <ReactNotifications />
      <div className="container">
        <Beadcrumb />
        <div className="pt-5"></div>
        <PageTitle pagetitle={WORKSPACE} />
        <div id="contact" className="input-data">
          <div className="" data-aos="fade-up">
            <div className="row pt-5">
              <div className="col-md-6">
                <h6>
                 Ubusabe bwanyu bw'inkunga y'umushinga wo guteza imbere ubuhinzi bugamije ubucuruzi no
                  kugabanya ibibubangamira (CDAT) bwakiriwe neza. Muzakurikirana uko mwasubijwe mwifashishije iyi kode:<strong className="text-primary font-weight-bol"><u>{application?.applicationcode}</u></strong>
                </h6>
                 
              </div>
              <div className="col-md-6">
                <h6>
                 Your grant application of Commercialization and De-Risking for Agricultural
                  Transformation (CDAT) Project is well received. For making follow up, you will use thise code: <strong className="text-primary font-weight-bol"><u>{application?.applicationcode}</u></strong>
                </h6>
                
              </div>
            </div>
            <div className="text-center pt-5">
              <NavLink to="/">Go to Home / Subira ahabanza</NavLink>
            </div>
            <div className="pt-5">
              <div className="row d-flex justify-content-center pt-5">
                <div className="col-md-2 d-flex justify-content-center">
                  <div>
                    <img alt="img" src={minagri} className="img-fluid h-25" />
                    <div className="text-center">
                      <span>MINAGRI</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 d-flex justify-content-center">
                  <img alt="img" src={rab} className="img-fluid h-25" />
                </div>
                <div className="col-md-2 text-center">
                  <img alt="img" src={bdf} className="h-25" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ApplicationDetails;
