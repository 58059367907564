import "./assets/vendor/bbootstrap/css/bootstrap.min.css";
import "./assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "./assets/vendor/glightbox/css/glightbox.min.css";
import "./assets/vendor/remixicon/remixicon.css";
import "./assets/css/main.css";
import { BrowserRouter as Router } from "react-router-dom";
import PersonalDetails from "./pages/personaldetails";
import { Helmet } from "react-helmet";
import { Route, Switch } from "react-router-dom";
import { PageNotFound } from "./pages/PageNotFound";
import CriteriasPage from "./pages/applicationcriterias";
import { AdminLoginPage } from "./pages/login";
import isEmpty from "lodash/isEmpty";
import { store } from "./store/store";
import { authLogout, authSuccess } from "./store/actions/auth";
import jwtDecode from "jwt-decode";
import { AdminHome, PeriodsList } from "./pages/admin/adminhome";
import {
  Criterias,
  CriteriasList,
  CriteriaValues,
  CriteriaValuesNew,
  CriteriaValuesSubs,
  CriteriaValuesSubsNew,
} from "./pages/admin/criterias";
import { ApplicationsList } from "./pages/admin/applications";
import TermsAndConditions from "./pages/terms";
import IntroductionPage from "./pages/intro";
import CriteriasPage2 from "./pages/applicationcriterias2";
import ApplicationDetails from "./pages/lastpage";
import { Reports } from "./pages/admin/reports";
import { ApplicationsReport } from "./pages/admin/applicationsreport";
import ResultPage from "./pages/result";
import { SendNotifications, UpdateDB } from "./pages/admin/sendletters";
import { AdminProfile } from "./pages/admin/adminprofile";
import PersonalDetailsV2 from "./pages/personaldetailsv2";
import CriteriasPageV2 from "./pages/applicationcriteriasv2";

const token = localStorage.jwt;

(async () => {
  try {
    if (isEmpty(token)) {
      store.dispatch(authLogout());
      return;
    }

    //decode token
    const decodedUser = jwtDecode(token);
    if (decodedUser) {
      store.dispatch(authSuccess({ token: token, user: decodedUser }));
    }
  } catch (err) {
    store.dispatch(authLogout());
    window.location.reload();
    return;
  }
})();

function App() {
  return (
    <>
      <Helmet>
        <title>
          CDAT - Commercialization & de-risking for Agricultural Transformation
        </title>
        <meta name="description" content="BDF description" />
        <meta
          name="keywords"
          content="funding, guarantee, leasing, agri business"
        />
        <link rel="icon" href="/logo512.png" />
      </Helmet>
      <Router>
        <Switch>
          <Route exact path="/">
            <IntroductionPage/>
          </Route>
          <Route exact path="/terms-and-conditions">
            <TermsAndConditions />
          </Route>
          <Route exact path="/personal-details">
            <PersonalDetailsV2 />
          </Route>
          <Route exact path="/application">
            <CriteriasPageV2 />
          </Route>
          <Route exact path="/application-next">
            <CriteriasPage2 />
          </Route>
          <Route exact path="/sign-in">
            <AdminLoginPage />
          </Route>
          <Route exact path="/last-page">
            <ApplicationDetails />
          </Route>
          <Route exact path="/admin-home/:objectId?">
            <AdminHome />
          </Route>
          <Route exact path="/admin/profile">
            <AdminProfile/>
          </Route>
          <Route exact path="/periods-objects-list">
            <PeriodsList />
          </Route>
          <Route exact path="/criterias/:objectId?">
            <Criterias />
          </Route>
          <Route exact path="/criterias-objects-list">
            <CriteriasList />
          </Route>
          <Route exact path="/criteria-values/:objectId?">
            <CriteriaValues />
          </Route>
          <Route exact path="/criteria-values-new/:parentId?/:objectId?">
            <CriteriaValuesNew />
          </Route>
          <Route exact path="/criteria-values-subs/:objectId?">
            <CriteriaValuesSubs />
          </Route>
          <Route exact path="/criteria-values-subs-new/:parentId?/:objectId?">
            <CriteriaValuesSubsNew />
          </Route>
          <Route exact path="/periods">
            <AdminHome />
          </Route>
          <Route exact path="/applications">
            <ApplicationsList />
          </Route>
          <Route exact path="/reports">
            <ApplicationsReport />
          </Route>
          <Route exact path="/applications-report">
            <ApplicationsReport />
          </Route>
          <Route exact path="/send-letters">
            <SendNotifications />
          </Route>
          <Route exact path="/update-db">
            <UpdateDB />
          </Route>
          <Route>
            <PageNotFound />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
