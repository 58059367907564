import axios from "../../axios-base";

export const GET_PERIODS_SUCCESS = "GET_PERIODS_SUCCESS";
export const GET_ONE_PERIODS_SUCCESS = "GET_ONE_PERIODS_SUCCESS";
export const GET_ONE_ACTIVE_PERIODS_SUCCESS = "GET_ONE_ACTIVE_PERIODS_SUCCESS";
export const GET_PERIODS_START = "GET_PERIODS_START";
export const GET_PERIODS_FAIL = "GET_PERIODS_FAIL";

export const getPeriodsSuccess = (data) => {
    return {
      type: GET_PERIODS_SUCCESS,
      payload: data,
    };
  };
  
  export const getOnePeriodsSuccess = (data) => {
    return {
      type: GET_ONE_PERIODS_SUCCESS,
      payload: data,
    };
  };

  export const getOneActivePeriodsSuccess = (data) => {
    return {
      type: GET_ONE_ACTIVE_PERIODS_SUCCESS,
      payload: data,
    };
  };
  
  export const getPeriodsStart = () => {
    return {
      type: GET_PERIODS_START,
    };
  };
  export const getPeriodsFail = (error) => {
    return {
      type: GET_PERIODS_FAIL,
      payload: error,
    };
  };
  
  export const getActivePeriods = () => {
    return (dispatch) => {
      dispatch(getPeriodsStart());
      axios
        .get(`/periods/active`)
        .then((response) => {
          dispatch(getOneActivePeriodsSuccess(response.data.results));
        })
        .catch((err) => {
          dispatch(getPeriodsFail(err));
        });
    };
  };

  export const getActivePeriodsAdmin = () => {
    return (dispatch) => {
      dispatch(getPeriodsStart());
      axios
        .get(`/periods/active/admin`)
        .then((response) => {
          dispatch(getOneActivePeriodsSuccess(response.data.results));
        })
        .catch((err) => {
          dispatch(getPeriodsFail(err));
        });
    };
  };

  export const getPeriods = (lang) => {
    return (dispatch) => {
      dispatch(getPeriodsStart());
      axios
        .get(`/periods`)
        .then((response) => {
          dispatch(getPeriodsSuccess(response.data.results));
        })
        .catch((err) => {
          dispatch(getPeriodsFail(err));
        });
    };
  };

  export const createPeriods = (object, token) => {
    return (dispatch) => {
      dispatch(getPeriodsStart());
      axios
        .post(`/periods/create`, object, {
          headers: { Authorization: token },
        })
        .then((response) => {
          dispatch(getOnePeriodsSuccess(response.data.results));
        })
        .catch((err) => {
          dispatch(getPeriodsFail(err));
        });
    };
  };
  
  export const getOnePeriods = (token, id) => {
    return (dispatch) => {
      dispatch(getPeriodsStart());
      axios
        .get(`/periods/find/${id}`, {
          headers: { Authorization: token },
        })
        .then((response) => {
          dispatch(getOnePeriodsSuccess(response.data.results));
        })
        .catch((err) => {
          dispatch(getPeriodsFail(err));
        });
    };
  };
  
  export const activatingPeriods = (token, id) => {
    return (dispatch) => {
      dispatch(getPeriodsStart());
      axios
        .put(`/periods/activating/${id}`, {
          headers: { Authorization: token },
        })
        .then((response) => {
          dispatch(getOnePeriodsSuccess(response.data.results));
        })
        .catch((err) => {
          dispatch(getPeriodsFail(err));
        });
    };
  };
  
  export const desActivatingPeriods = (token, id) => {
    return (dispatch) => {
      dispatch(getPeriodsStart());
      axios
        .put(`/periods/desactivating/${id}`, {
          headers: { Authorization: token },
        })
        .then((response) => {
          dispatch(getOnePeriodsSuccess(response.data.results));
        })
        .catch((err) => {
          dispatch(getPeriodsFail(err));
        });
    };
  };
  
  export const updatePeriods = (token, object) => {
    return (dispatch) => {
      dispatch(getPeriodsStart());
      axios
        .put(`/periods/edit/${object?.id}`, object, {
          headers: { Authorization: token },
        })
        .then((response) => {
          dispatch(getOnePeriodsSuccess(response.data.results));
        })
        .catch((err) => {
          dispatch(getPeriodsFail(err));
        });
    };
  };
  