import { updateObject } from "../../utils/utility";
import {
  GET_CRITERIAS_START,
  GET_CRITERIAS_SUCCESS,
  GET_ONE_CRITERIAS_SUCCESS,
  GET_CRITERIAS_FAIL,
  GET_CRITERIA_VALUES_START,
  GET_CRITERIA_VALUES_SUCCESS,
  GET_ONE_CRITERIA_VALUES_SUCCESS,
  GET_CRITERIA_VALUES_FAIL,
  GET_CRITERIA_VALUES_SUBS_START,
  GET_CRITERIA_VALUES_SUBS_SUCCESS,
  GET_ONE_CRITERIA_VALUES_SUBS_SUCCESS,
  GET_CRITERIA_VALUES_SUBS_FAIL,
} from "../actions/cdat";

const initialReducer = {
  criteria: null,
  criterias: [],
  criteriasLoading: false,
  criteriasFail: null,
  criteriaValue: null,
  criteriaValues: [],
  criteriaValuesLoading: false,
  criteriaValuesFail: null,
  criteriaValueSub: null,
  criteriaValueSubs: [],
  criteriaValueSubsLoading: false,
  criteriaValueSubsFail: null,
};

const getCriteriasStart = (state, action) => {
  return updateObject(state, {
    criterias: [],
    criteria: null,
    criteriasLoading: true,
    criteriasFail: null,
  });
};

const getCriteriasSuccess = (state, action) => {
  return updateObject(state, {
    criterias: action.payload,
    criteriasLoading: false,
    criteriasFail: null,
    criteria: null,
  });
};

const getOneCriteriasSuccess = (state, action) => {
  return updateObject(state, {
    criteria: action.payload,
    criterias: [],
    criteriasLoading: false,
    criteriasFail: null,
  });
};

const getCriteriasFail = (state, action) => {
  return updateObject(state, {
    criterias: [],
    criteria: null,
    criteriasLoading: false,
    criteriasFail: action.payload,
  });
};

const getCriteriaValuesStart = (state, action) => {
  return updateObject(state, {
    criteriaValues: [],
    criteriaValue: null,
    criteriaValuesLoading: true,
    criteriaValuesFail: null,
  });
};

const getCriteriaValuesSuccess = (state, action) => {
  return updateObject(state, {
    criteriaValues: action.payload,
    criteriaValuesLoading: false,
    criteriaValuesFail: null,
    criteriaValue: null,
  });
};

const getOneCriteriaValuesSuccess = (state, action) => {
  return updateObject(state, {
    criteriaValue: action.payload,
    criteriaValues: [],
    criteriaValuesLoading: false,
    criteriaValuesFail: null,
  });
};

const getCriteriaValuesFail = (state, action) => {
  return updateObject(state, {
    criteriaValues: [],
    criteriaValue: null,
    criteriaValuesLoading: false,
    criteriaValuesFail: action.payload,
  });
};

const getCriteriaValuesSubsStart = (state, action) => {
  return updateObject(state, {
    criteriaValueSubs: [],
    criteriaValueSub: null,
    criteriaValueSubsLoading: true,
    criteriaValueSubsFail: null,
  });
};

const getCriteriaValuesSubsSuccess = (state, action) => {
  return updateObject(state, {
    criteriaValueSubs: action.payload,
    criteriaValueSubsLoading: false,
    criteriaValueSubsFail: null,
    criteriaValueSub: null,
  });
};

const getOneCriteriaValuesSubsSuccess = (state, action) => {
  return updateObject(state, {
    criteriaValueSub: action.payload,
    criteriaValueSubs: [],
    criteriaValueSubsLoading: false,
    criteriaValueSubsFail: null,
  });
};

const getCriteriaValuesSubsFail = (state, action) => {
  return updateObject(state, {
    criteriaValueSubs: [],
    criteriaValueSub: null,
    criteriaValueSubsLoading: false,
    criteriaValueSubsFail: action.payload,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case GET_CRITERIAS_START:
      return getCriteriasStart(state, action);
    case GET_CRITERIAS_SUCCESS:
      return getCriteriasSuccess(state, action);
    case GET_ONE_CRITERIAS_SUCCESS:
      return getOneCriteriasSuccess(state, action);
    case GET_CRITERIAS_FAIL:
      return getCriteriasFail(state, action);
      case GET_CRITERIA_VALUES_START:
      return getCriteriaValuesStart(state, action);
    case GET_CRITERIA_VALUES_SUCCESS:
      return getCriteriaValuesSuccess(state, action);
    case GET_ONE_CRITERIA_VALUES_SUCCESS:
      return getOneCriteriaValuesSuccess(state, action);
    case GET_CRITERIA_VALUES_FAIL:
      return getCriteriaValuesFail(state, action);
      case GET_CRITERIA_VALUES_SUBS_START:
      return getCriteriaValuesSubsStart(state, action);
    case GET_CRITERIA_VALUES_SUBS_SUCCESS:
      return getCriteriaValuesSubsSuccess(state, action);
    case GET_ONE_CRITERIA_VALUES_SUBS_SUCCESS:
      return getOneCriteriaValuesSubsSuccess(state, action);
    case GET_CRITERIA_VALUES_SUBS_FAIL:
      return getCriteriaValuesSubsFail(state, action);
    default:
      return state;
  }
};

export default reducer;
