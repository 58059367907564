import { useEffect, useState } from "react";
import Header from "../components/header";
import {
  ActivateButton,
  InputEmail,
  InputNationalId,
  InputTelephone,
  InputText2,
  SelectInput,
  SubmitButton,
} from "../components/inputs";
import { Beadcrumb, CountDown, PageTitle } from "../components/utils";
import { useTranslation } from "react-i18next";
import constants from "../components/constants";
import { Provinces, Districts, Sectors } from "rwanda";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  applicationInitialization,
  getApplicantByEmail,
  getApplicantByNationalId,
  getApplicantByTelephone,
  getApplicationsStart,
  getApplicationsSuccesInit,
} from "../store/actions/applications";
import { ReactNotifications } from "react-notifications-component";
import { getActivePeriods } from "../store/actions";
// import { useDispatch, useSelector } from "react-redux";

function TermsAndConditions() {
  const WORKSPACE = "";
  const { t } = useTranslation();
  const history = useHistory();
  const [acceppted, setaccepted] = useState(false);
  const application = useSelector((state) => state.applications.application);
  const activePeriod = useSelector((state) => state.periods?.activePeriod);
  // const application = useSelector((state) => state.applications.application);
  const dispatch = useDispatch();

  const deadline = useSelector(
    (state) => state.periods?.activePeriod?.closingdate
  );

  const changeHandler = (data) => {
    alert(data);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const box = document.getElementById("ckbox");
    if (box?.checked && activePeriod !== null) {
      history.push("/personal-details");
    } else if (box?.checked && activePeriod === null) {
      alert(
        "The application period has ended"
      );
    } else {
      alert(
        "The application period has ended"
      );
      // alert(
      //   "Please accept the terms and condtions/Banza wemere amategeko n'amabwiriza"
      // );
    }
  };

  useEffect(() => {
    dispatch(getActivePeriods());
    dispatch(applicationInitialization());
  }, []);

  return (
    <>
      <Header />
      <ReactNotifications />
      <div className="container">
        <Beadcrumb />
        <PageTitle pagetitle={WORKSPACE} />
        <div id="contact" className="input-data pt-3">
          <div className="" data-aos="fade-up">
            <div className="php-email-form">
              <h5 className="text-primary text-center">
                Inclusion list/Urutonde rw’ibikorwa byemewe guhabwa inkunga
                nyunganizi mu mushinga CDAT
              </h5>
              <div className="row d-flex">
                <div className="col-md-6">
                  <ul>
                    <li>
                    Imishinga izaterwa inkunga ni igizwe ni ibi bikurikira:
                      <ol>
                        <li>Ku ibinyampeke/ibinyamisogwe (ibigori/maize, umuceri/rice, ibishyimbo/beans):</li>
                        <ul>
                        <li>Ubuhunikiro bwo gufata neza umusaruro nibura ungana na toni ibihumbi bitanu (5,000 tons) ku ibinyampeke/ibinyamisogwe </li>
                        <li>Imashini zifashishwa mu kumisha umusaruro w’ ibinyampeke zishobora kumisha nibura toni icumi icyarimwe  (fixed and mobile dryer with the capacity of at least 10MT/batch)</li>
                        <li>Imashini zihungura ibinyampeke/ibinyamisogwe zishobora guhungura nibura  toni eshatu ku isaha (Grain sheller/thresher with the capacity of at least 3/MT/hr)</li>
                        <li>Imashini itoranya intete(grain) igendeye ku bunini n’ubwiza bwazo (sorting and grading machine) </li>
                        </ul>
                        <li>Ku birayi (Irish Potatoes)</li>
                        <ul>
                        <li>Ubuhunikiro bwo gufata neza umusaruro nibura ungana na toni magana atanu (500 tons) </li>
                        <li>Imashini itoranya ibirayi igendeye ku bunini (Grading machine)</li>
                        <li>Plastic Pallets</li>
                        <li>Imashini zipakira zikanapakurura umusaruro/imbuto</li>
                        </ul>
                      </ol>
                    </li>
                    <li
                      className={
                        activePeriod?.allowoldapplicants === "false"
                          ? "d-show"
                          : "d-none"
                      }
                    >
                      The Applicant must not have received one of these MINAGRI
                      grants RDDP, PASP, PRICE I, PRICE II or SAIP / Usaba agomba kuba
                      atarigeze abona inkunga ya MINAGRI muri izi RDDP, PASP,
                      PRICE I, PRICE II cyangwa SAIP
                    </li>
                  
                  </ul>
                </div>
                <div className="col-md-6">
                  <ul>
                    <li>
                      Icyitonderwa
                      <ol>
                      <li>Gusaba inkunga nyunganizi ku mishinga igizwe n’ubuhunikiro cyangwa imashini yumisha umusaruro (dryer)gusa biremewe.</li>
                      <li>Imishinga yo kugura izindi mashini zitari dryer igomba kuba ikomatanyije n’ubuhunikiro cyangwa imishini yumisha umusaruro kugirango yemererwe inkunga.</li>
                      <li>Usaba inkunga mu kugura imishani zindi zunganira ubuhunikiro zitari dryer ni ufite ubunikiro bubika umusaruro utari munsi ya toni igihumbi (1,000 tons) z’ibinyampeke/ibinyamisogwe na toni 100 ku ibirayi.</li>
                      <li>Imbata (template) yifashishwa mu gutegura imishinga iboneka ku rubuga rwa <a  href="https://bdffiles.bdf.rw/site/publicationfiles/9819682d-bf3c-40fa-888c-6dc23942dfd6CDAT_BUSINESS_PLAN_TEMPLATE.pdf" target="_blank">BDF</a></li>
                     <li>Inkunga nyunganizi ntirenza mirongo itanu ku ijana (50%) by’agaciro kose k’ishoramari ku bazahabwa inkunga bwa mbere n’imishinga ya MINAGRI na mirongo itatu ku ijana (30%) ku bigeze guterwa inkunga  n’iyo mishinga.</li>
                      </ol>
                    </li>
                    <li>
                    If an application found breaching these rules, it will be
                    canceled at any stage / Ubusabe buzasangwa butarubahirije
                    aya mabwiriza, ntibuzemerwa.
                  </li>
                  </ul>
                </div>
              </div>
              <div className="text-center">
                <input
                  id="ckbox"
                  type="checkbox"
                  //   value={acceppted}
                  //   onChange={() => changeHandler(!acceppted)}
                />
                Emeza ko umushinga wawe wijuje ibisabwa byavuzwe haruguru / Tick to
                confirm that your business project met above terms and conditions{" "}
              </div>
              <div>
                <input
                  onClick={onSubmit}
                  className="btn btn-success"
                  value={"Continue/Komeza"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TermsAndConditions;
