import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import constants from "../components/constants";
import Header from "../components/header";
import {
  ActivateButton,
  EditButton,
  InputText2,
  SelectInput,
  SelectInputCriteria,
  SubmitButton,
} from "../components/inputs";
import {
  Beadcrumb,
  CountDown,
  ObjectDetails,
  PageTitle,
} from "../components/utils";
import { getActiveCriterias } from "../store/actions";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import {
  createApplications,
  getApplicationsSuccesInit,
} from "../store/actions/applications";
import { errorNotificationDataMissing } from "../components/notifications";
import { ReactNotifications } from "react-notifications-component";

function CriteriasPage2() {
  const WORKSPACE =
    "SELECT WHAT APPLIES TO YOUR PROJECT/HITAMO IBISOBANURA NEZA UMUSHINGA WAWE";
  const [clicked, setclicked] = useState();
  const [valuess, setvaluess] = useState([]);
  const [criterias, setcriterias] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const objectsCriteria = useSelector((state) => state.cdat.criterias);
  const application = useSelector((state) => state.applications.application);
  const [projectname, setprojectname] = useState("");
  const [owncontribution, setowncontribution] = useState("");
  const [totalinvestment, settotalinvestment] = useState("");

  let firstSeven = [];
  let lastItems = [];

  const [show, setShow] = useState(false);
  const handleClose = (e) => {
    e.preventDefault();
    setShow(false);
  };

  const setTheVal = (val, i) => {
    const remnant = criterias.filter(
      (note) => note.criteriaId !== i.criteriaId
    );
    setcriterias([...remnant, i]);
  };

  const refs = useRef([]);
  refs.current = [];

  const addToRefs = (ref) => {
    if (ref && !refs.current.includes(ref)) {
      refs.current.push(ref);
    }
  };

  if (objectsCriteria.length > 7) {
    for (let i = 0; i < 7; i++) {
      firstSeven.push(objectsCriteria[i]);
    }
    for (let i = 7; i < objectsCriteria.length; i++) {
      lastItems.push(objectsCriteria[i]);
    }
  }

  useEffect(() => {
    dispatch(getActiveCriterias());
  }, []);

  const onPreview = (e) => {
    e.preventDefault();
    if (criterias.length !== objectsCriteria.length) {
      errorNotificationDataMissing("Application");
      setclicked(true);
    } else {
      setclicked(false);
      setShow(true);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const object = {
      applicant: application,
      criterias,
    };
  dispatch(createApplications(object));
    setShow(false);
  };

  const back = (e) => {
    e.preventDefault();
    dispatch(getApplicationsSuccesInit(application));
    history.push("/application");
  };

  return (
    <>
      <Header />
      <ReactNotifications />
      <div className="container">
        <Modal show={show} onHide={handleClose} size="xl">
          <Modal.Body>
            <div className="row px-5 pt-3 pb-5">
              <h6 className="text-primary text-center pb-3">
                Umwirondoro/Application Details
              </h6>
              <div className="col-sm-12 col-md-4">
                <ObjectDetails
                  title={"Amazina/Names"}
                  value={application?.firstname + " " + application?.lastname}
                />
                <ObjectDetails
                  title={"Igitsina/Gender"}
                  value={application?.gender}
                />
                <ObjectDetails
                  title={"Irangamuntu/National ID"}
                  value={application?.nationalId}
                />
                <ObjectDetails
                  title={"Telefoni/Telephone"}
                  value={application?.telephone}
                />
                <ObjectDetails
                  title={"Imeyiri/Email"}
                  value={application?.email}
                />
                <ObjectDetails
                  title={"Intara/Province"}
                  value={application?.province}
                />
                <ObjectDetails
                  title={"Akarere/District"}
                  value={application?.district}
                />
                <ObjectDetails
                  title={"Sector/Umurenge"}
                  value={application?.sector}
                />
              </div>
              <div className="col-sm-12 col-md-8">
                {criterias.map((data, index) => (
                  <ObjectDetails
                    key={index}
                    title={data?.criteria}
                    value={data?.criteriaValue}
                  />
                ))}
                <div className="mb-3"></div>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary text-white"
                onClick={handleClose}
              >
                Hindura/Edit
              </button>{" "}
              <span className="px-3"></span>
              <button className="btn btn-success text-white" onClick={onSubmit}>
                {"Ohereza/Submit"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <Beadcrumb />
        <PageTitle pagetitle={WORKSPACE} />
        <div id="contact" className="input-data">
          <div className="" data-aos="fade-up">
            <div className="row d-flex php-email-form">
              <div className="col-md-12">
                <div className="row d-flex">
                  {lastItems?.map((object, index) => (
                    <div className="col-sm-10 col-md-6" key={index}>
                      <div className="form-group py-1">
                        <SelectInputCriteria
                          key={index}
                          id={index}
                          title={object?.criteria}
                          startingValue={"HITAMO/SELECT"}
                          options={object?.criteriaValues}
                          value={valuess[index + 1]}
                          changed={setTheVal}
                          criteriaId={object?.id}
                          criteria={object?.criteria}
                          isClicked={clicked}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="pt-3">
                  <div className="row gx-5"></div>
                  <SubmitButton action={"Back/Subira Inyuma"} onSubmit={back} />
                  <span className="px-2"></span>
                  <SubmitButton
                    classes="bg-success"
                    action={"Continue/Komeza"}
                    onSubmit={onPreview}
                  />
                </div>
              </div>
              <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CriteriasPage2;
