import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import AdminHeader from "../../components/adminheader";
import * as actions from "../../store/actions";
import moment from "moment";
import {
  ActivateButton,
  EditButton,
  InputNationalId,
  InputText2,
  InputTextArea,
  InputTextSearch,
  MiniTitle,
  PageLink,
  SelectInput,
  SubmitButton,
  ViewButton,
  ViewContentBtn,
} from "../../components/inputs";
import constants from "../../components/constants";
import {
  Beadcrumb,
  ObjectDetails,
  PageTitle,
  formatDecimals,
} from "../../components/utils";
import { useDispatch, useSelector } from "react-redux";
import { ReactNotifications } from "react-notifications-component";
import Modal from "react-bootstrap/Modal";
import {
  sendingLettersNotification,
  successUpdateNotification,
  waitPublishingDate,
} from "../../components/notifications";
import * as XLSX from "xlsx/xlsx.mjs";

export const SendNotifications = () => {
  const WORKSPACE = "SEND NOTIFICATIONS";
  const loading = useSelector(
    (state) => state.applications.applicationsLoading
  );
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const period = useSelector((state) => state.periods?.activePeriod);
  const application = useSelector((state) => state.applications.application);
  const objectList = useSelector((state) => state.applications.applications);

  useEffect(() => {
    dispatch(actions.getApplications());
    dispatch(actions.getActivePeriods());
  }, []);

  const [searchkey, setsearchkey] = useState("");
  const [searchvalue, setsearchvalue] = useState("");
  const [message, setmessage] = useState("");
  const [applicationcode, setapplicationcode] = useState("");
  const [show, setShow] = useState(false);
  const [clicked, setclicked] = useState(false);
  const history = useHistory();
  const setTheSearchkey = (value) => {
    setsearchkey(value);
    setclicked(false);
  };

  const sendLetters = (e) => {
    e.preventDefault();
    dispatch(actions.sendLetters(token));
    sendingLettersNotification();
  };

  const updateDB = (e) => {
    e.preventDefault();
    history.push("/update-db");
  };

  const searchfn = () => {
    if (searchkey.trim() === "" || searchvalue.trim() === "") {
      setclicked(true);
    } else {
      dispatch(actions.getApplicationsBySearching(searchkey, searchvalue));
    }
  };

  const sendLettersApprovedIC = () => {
    if (applicationcode === "" || applicationcode.trim() === "") {
      setclicked(true);
    } else {
      dispatch(actions.sendSMSToApplicantsInIC(applicationcode));
      sendingLettersNotification();
      setapplicationcode("");
    }
  };

  const sendLettersRejectedIC = () => {
    console.log("SEND SMS APPROVED");
  };

  useEffect(() => {
    dispatch(actions.getActivePeriodsAdmin());
  }, []);

  return (
    <div className="">
      <AdminHeader />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        <section id="contact" className="input-data">
          <div className="container" data-aos="fade-up">
            <div className="row d-flex php-email-form">
              <div className="col-md-3 pt-1">
                <div className="form-group py-1 col-md-12">
                  <SelectInput
                    title="Select SMS TO APPLICANTS IN"
                    startingValue={"HITAMO/SELECT"}
                    options={constants.smsTo}
                    value={searchkey}
                    changed={setsearchkey}
                    isClicked={clicked}
                  />
                </div>
                <div className="d-flex gy-5 pt-5">
                  <div className="flex flex-column h-50">
                    <span className="text-primary pb-3">
                      Click the button below to update the Databased based in IC
                      Minutes
                    </span>
                    <button
                      className="btn btn-primary text-white"
                      onClick={updateDB}
                    >
                      Update DB
                    </button>{" "}
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <div className="text-center">
                  <h5 className="bdf-bluefontcolor">{WORKSPACE}</h5>
                </div>
                <div
                  className={
                    searchkey === "preselected" ? "d-show pl-5" : "d-none"
                  }
                >
                  <span className="text-center bdf-bluefontcolor">
                    To send notifications to preselected, check first if marks
                    for selected, waiting and rejected applications are setted
                  </span>
                  <div className="row">
                    <div className="col-md-6">
                      <MiniTitle title="Active Call For Proposal Period" />
                      <ObjectDetails title="Name" value={period?.projectname} />
                      <ObjectDetails
                        title="Opening Date"
                        value={moment(period?.openingdate).format("DD/MM/YYYY")}
                      />
                      <ObjectDetails
                        title="Closing Date"
                        value={moment(period?.closingdate).format("DD/MM/YYYY")}
                      />
                      <ObjectDetails
                        title="Selected Pass Marks"
                        value={period?.passmark}
                      />
                      <ObjectDetails
                        title="Waiting List Marks"
                        value={period?.waitingmarks}
                      />
                      <ObjectDetails
                        title="Rejected List Marks"
                        value={period?.rejectionmarks}
                      />
                    </div>
                    <div className="col-md-6 pt-5">
                      <span className="text-danger d-none">{message}</span>
                      <div className="pt-3">
                        <div className="row gx-5"></div>
                        <SubmitButton
                          action={"SEND SMS"}
                          disabled={clicked}
                          onSubmit={sendLetters}
                        />
                        <span className="px-2"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={searchkey === "approvedIC" ? "d-show" : "d-none"}
                >
                  <span className="text-center bdf-bluefontcolor">
                    Sending notifications to applicants in IC, enter their
                    application code.
                  </span>
                  <div className="col-sm-10 col-md-4">
                    <div className="form-group py-1">
                      <InputText2
                        title={"Application Code"}
                        value={applicationcode}
                        name="applicationcode"
                        placeholder={"code"}
                        isClicked={clicked}
                        onChange={(e) => setapplicationcode(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="pt-3">
                    <div className="row gx-5"></div>
                    <SubmitButton
                      action={"SEND SMS"}
                      disabled={clicked}
                      onSubmit={sendLettersApprovedIC}
                    />
                    <span className="px-2"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export const UpdateDB = () => {
  const WORKSPACE = "UPDATE THE DATABASE";
  const loading = useSelector(
    (state) => state.applications.applicationsLoading
  );
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const period = useSelector((state) => state.periods?.activePeriod);
  const application = useSelector((state) => state.applications.application);
  const objectList = useSelector((state) => state.applications.applications);

  // useEffect(() => {
  //   dispatch(actions.getApplications());
  //   dispatch(actions.getActivePeriods());
  // }, []);

  const [searchkey, setsearchkey] = useState("");
  const [searchvalue, setsearchvalue] = useState("");
  const [message, setmessage] = useState("");
  const [applicationcode, setapplicationcode] = useState("");
  const [show, setShow] = useState(false);
  const [clicked, setclicked] = useState(false);
  const history = useHistory();
  const [applicationcodelist, setapplicationcodelist] = useState("");
  const setTheSearchkey = (value) => {
    setsearchkey(value);
    setclicked(false);
  };

  const sendLetters = (e) => {
    e.preventDefault();
    dispatch(actions.sendLetters(token));
    sendingLettersNotification();
  };

  const updateDB = (e) => {
    e.preventDefault();
    history.push("/update-db");
  };

  const updateTheDBApproved = (e) => {
    e.preventDefault();
    const allcodes = applicationcodelist?.split("CDAT");
    const codestrimmed = [];
    allcodes.forEach((element) => {
      if (element?.trim() !== "") {
        codestrimmed?.push(element?.trim()?.replace("-01-", "CDAT-01-"));
      }
    });
    if (codestrimmed?.length > 0) {
      if (
        window.confirm(
          `Are you sure you want to update these ${codestrimmed?.length} applicants as approved in IC?`
        )
      ) {
        dispatch(actions.approveApplicantsInIC(codestrimmed));
        setapplicationcodelist("");
       successUpdateNotification("APPROVED APPLICANTS", "APPROVED IN IC");
      } else {
      }
    } else {
      alert("You can't update empty list");
    }
  };

  const updateTheDBApprovedOnCondition = (e) => {
    e.preventDefault();
    const allcodes = applicationcodelist?.split("CDAT");
    const codestrimmed = [];
    allcodes.forEach((element) => {
      if (element?.trim() !== "") {
        codestrimmed?.push(element?.trim()?.replace("-01-", "CDAT-01-"));
      }
    });
    if (codestrimmed?.length > 0) {
      if (
        window.confirm(
          `Are you sure you want to update these ${codestrimmed?.length} applicants as approved on condition in IC?`
        )
      ) {
        dispatch(actions.approveApplicantsInICondition(codestrimmed));
        setapplicationcodelist("");
       successUpdateNotification("ON CONDITION APPROVED APPLICANTS", "APPROVED IN IC");
      } else {
      }
    } else {
      alert("You can't update empty list");
    }
  };

  const updateTheDBRejected = (e) => {
    e.preventDefault();
    const allcodes = applicationcodelist?.split("CDAT");
    const codestrimmed = [];
    allcodes.forEach((element) => {
      if (element?.trim() !== "") {
        codestrimmed?.push(element?.trim()?.replace("-01-", "CDAT-01-"));
      }
    });
    if (codestrimmed?.length > 0) {
      if (
        window.confirm(
          `Are you sure you want to update these ${codestrimmed?.length} applicants as rejected in IC?`
        )
      ) {
        dispatch(actions.rejectApplicantsInIC(codestrimmed));
        setapplicationcodelist("");
        successUpdateNotification("REJECTED APPLICANTS", "REJECTED IN IC");
      } else {
      }
    } else {
      alert("You can't update empty list");
    }
  };

  const searchfn = () => {
    if (searchkey.trim() === "" || searchvalue.trim() === "") {
      setclicked(true);
    } else {
      dispatch(actions.getApplicationsBySearching(searchkey, searchvalue));
    }
  };

  const sendLettersApprovedIC = () => {
    if (applicationcode === "" || applicationcode.trim() === "") {
      setclicked(true);
    } else {
      dispatch(actions.sendSMSToApplicantsInIC(applicationcode));
      sendingLettersNotification();
      setapplicationcode("");
    }
  };

  const sendLettersRejectedIC = () => {
    console.log("SEND SMS APPROVED");
  };

  useEffect(() => {
   
  }, []);

  return (
    <div className="">
      <AdminHeader />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        <section id="contact" className="input-data">
          <div className="container" data-aos="fade-up">
            <div className="row d-flex php-email-form">
              <div className="">
                <div className="text-center">
                  <h5 className="bdf-bluefontcolor">{WORKSPACE}</h5>
                </div>
                <InputTextArea
                  title="Application codes list"
                  value={applicationcodelist}
                  placeholder={"codes"}
                  rowsNbr={5}
                  onChange={(e) => setapplicationcodelist(e.target.value)}
                />
                <div className="row">
                  <div className="d-flex gy-5 pt-5">
                    <div className="flex flex-column h-50">
                      <button
                        className="btn btn-primary text-white"
                        onClick={updateTheDBApproved}
                      >
                        Approved in IC
                      </button>{" "}
                      <span className="w-3"></span>
                      <button
                      className="btn btn-secondary text-white"
                      onClick={updateTheDBApprovedOnCondition}
                    >
                      IC Conditioned Aproved
                    </button>{" "}
                    <span className="w-3"></span>
                      <button
                        className="btn btn-danger text-white"
                        onClick={updateTheDBRejected}
                      >
                        Rejected in IC
                      </button>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};
