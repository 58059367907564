import { useEffect, useState } from "react";
import { HeaderLink, HomeHeaderLink } from "../components/links";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../store/actions/auth";
import { useHistory } from "react-router-dom";
const AdminHeader = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const token = useSelector((state) => state.auth.token);
  const displayName = useSelector((state) => state.auth.displayName);
  const role = useSelector((state) => state.auth.role);

  const logouti = (e) => {
    e.preventDefault();
    dispatch(logout());
    history.push("/sign-in");
  };

  const profile = (e) => {
    e.preventDefault();
    history.push("/admin/profile");
  };

  useEffect(() => {
  }, [token]);

  return (
    <header
      id="header"
      className="header d-flex align-items-center fixed-top bg-bdf-blue"
    >
      <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
        <nav id="navbar" className={token ? "navbar" : "d-none"}>
          <ul className="me-3">
            <li className={role==="admin"?"dropdown":"d-none"}>
              <HeaderLink page="/periods" name={"Periods"} />
            </li>
            <li className={role==="admin"?"dropdown":"d-none"}>
              <HeaderLink page="/criterias" name={"Criteria"} />
            </li>
            <li className={role==="admin"?"dropdown":"d-none"}>
              <HeaderLink page="/applications" name={"Applications"} />
            </li>
            <li className={"dropdown"}>
              <HeaderLink page="/reports" name={"Reports"} />
            </li>
          </ul>
        </nav>
        <div className={token ? "row user-profile" : "d-none"}>
          <span className="col-md-8">{displayName}</span>
          <i onMouseDown={profile} className="bi-person-heart col-md-2"></i>
          <i onMouseDown={logouti} className="bi-lock-fill col-md-2"></i>
        </div>
      </div>
    </header>
  );
};

export default AdminHeader;
