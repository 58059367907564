import { updateObject } from "../../utils/utility";
import {
    GET_PERIODS_START,
    GET_PERIODS_SUCCESS,
    GET_ONE_PERIODS_SUCCESS,
    GET_ONE_ACTIVE_PERIODS_SUCCESS,
    GET_PERIODS_FAIL,
  } from "../actions/periods";

const initialReducer = {
  period: null,
  periods: [],
  periodsLoading: false,
  periodsFail: null,
  activePeriod: null,
};

const getPeriodsStart = (state, action) => {
  return updateObject(state, {
    periods: [],
    period: null,
    periodsLoading: true,
    periodsFail: null,
  });
};

const getPeriodsSuccess = (state, action) => {
  return updateObject(state, {
    periods: action.payload,
    periodsLoading: false,
    periodsFail: null,
    period: null,
  });
};

const getOnePeriodsSuccess = (state, action) => {
  return updateObject(state, {
    period: action.payload,
    periods: [],
    periodsLoading: false,
    periodsFail: null,
  });
};

const getOneActivePeriodsSuccess = (state, action) => {
    return updateObject(state, {
      period: null,
      activePeriod: action.payload,
      periods: [],
      periodsLoading: false,
      periodsFail: null,
    });
  };

const getPeriodsFail = (state, action) => {
  return updateObject(state, {
    periods: [],
    period: null,
    periodsLoading: false,
    periodsFail: action.payload,
  });
};

const reducer = (state = initialReducer, action) => {
    switch (action.type) {
      case GET_PERIODS_START:
        return getPeriodsStart(state, action);
      case GET_PERIODS_SUCCESS:
        return getPeriodsSuccess(state, action);
      case GET_ONE_PERIODS_SUCCESS:
        return getOnePeriodsSuccess(state, action);
        case GET_ONE_ACTIVE_PERIODS_SUCCESS:
        return getOneActivePeriodsSuccess(state, action);
      case GET_PERIODS_FAIL:
        return getPeriodsFail(state, action);
      default:
        return state;
    }
  };
  
  export default reducer;
  