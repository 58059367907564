import { useEffect, useState } from "react";
import Header from "../components/header";
import rab from "../rab.png";
import bdf from "../bdf.PNG";
import minagri from "../rwanda.png";
import {
  ActivateButton,
  InputEmail,
  InputNationalId,
  InputTelephone,
  InputText2,
  SelectInput,
  SubmitButton,
} from "../components/inputs";
import {
  Beadcrumb,
  CountDown,
  PageTitle,
  ResultCard,
} from "../components/utils";
import { useTranslation } from "react-i18next";
import constants from "../components/constants";
import { Provinces, Districts, Sectors } from "rwanda";
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  applicationInitialization,
  findOneOldApplicants,
  getApplicantByEmail,
  getApplicantByNationalId,
  getApplicantByTelephone,
  getApplicationsSuccesInit,
  getOneApplicationsByCode,
} from "../store/actions/applications";
import { successApplyingNotification } from "../components/notifications";
import { ReactNotifications } from "react-notifications-component";
// import { useDispatch, useSelector } from "react-redux";

function ResultPage() {
  const WORKSPACE = "KUREBA IBISUBIZO/APPLICATION RESULTS";
  const dispatch = useDispatch();
  const application = useSelector((state) => state.applications.application);
  const applicationLoading = useSelector(
    (state) => state.applications.applicationsLoading
  );
  const [code, setcode] = useState("");
  const [telephone, settelephone] = useState("");
  const [message, setmessage] = useState("");
  const [submitt, setsubmitt] = useState(false);

  let response = "REJECTED";

  useEffect(() => {
    // setsubmitt(false)
  }, []);

  const onSubmit = () => {
    if (code.trim() !== "" && code.length > 10) {
      setsubmitt(true);
    }
    dispatch(getOneApplicationsByCode(code, telephone));
  };
  return (
    <>
      <Header />
      <div className="container">
        <Beadcrumb />
        <div className="pt-3"></div>
        <PageTitle pagetitle={WORKSPACE} />
        <div id="contact" className="input-data">
          <div className="" data-aos="fade-up">
            <div className="pt-5 ">
              <div className="row d-flex justify-content-center">
                <div className="col-sm-10 col-md-8">
                  <p>
                    Kureba ibisubizo koresha kode y'ubusabe na nimero ya
                    telefone (To check result use application code and telephone
                    number).
                  </p>
                  <p></p>
                  <div className="form-group py-1">
                    <InputText2
                      title={"Application Code/Kode y'ubusabe (CDAT-01-000000)"}
                      value={code}
                      name="code"
                      placeholder={"Code"}
                      onChange={(e) => {
                        setcode(e.target.value);
                        setsubmitt(false);
                      }}
                    />
                  </div>
                  <div className="form-group py-1">
                    <InputText2
                      title={"Telephone number/Nimero ya telefone (07...)"}
                      value={telephone}
                      name="telephone"
                      placeholder={"Telephone (07...)"}
                      onChange={(e) => {
                        settelephone(e.target.value);
                        setsubmitt(false);
                      }}
                    />
                  </div>
                  <div className="pt-3 pb-3">
                    <SubmitButton
                      classes="btn btn-primary"
                      action={"Check Result/Reba Igisubizo"}
                      onSubmit={onSubmit}
                    />
                  </div>
                  <div className="pb-2 text-center d-none"><a
                  className="pb-2 text-primary" target="_blank"
                  href="https://bdffiles.bdf.rw/site/publicationfiles/6e075268-4b81-4c61-a24b-3b339ea71601pre_selectedCDAT.pdf"
                >
                  List of CDAT Pre-selected Applicants/ Reba Urutonde rw'abemerewe gutanga inyigo y'umushinga
                </a></div>
                 <div className="text-center d-none">
                 <a
                 className="pb-2 text-secondary"
                 href="https://bdffiles.bdf.rw/site/bdffiles/CDAT_BUSINESS_PLAN_TEMPLATE.docx"
               >
                 Download business plan template / Reba inyandiko igufasha
                 gutegura umushinga
               </a>
                 </div>
                </div>
                <div className="col-md-4 d-none">
                  <div className="pt-5">
                    <span className="bg-success text-white px-3 py-1">
                      Abemerewe inkunga / Accepted for grant
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                submitt &&
                !applicationLoading &&
                (application === null || application?.icdecision === null)
                  ? "d-show"
                  : "d-none"
              }
            >
              <div
                className={"alert alert-warning text-center pt-3"}
                role="alert"
              >
                <p>
                {application?.firstname} Muraho! Ntabwo ubusabe bwanyu bwakomeje mu cyiciro cyo gutanga umushinga.
                </p>{" "}
              </div>
            </div>
            <div
              className={
                submitt &&
                application !== null && 
                application?.icdecision === "ACCEPTED"
                  ? "d-show"
                  : "d-none"
              }
            >
              <div
                className={"alert alert-success text-center pt-3"}
                role="alert"
              >
                <p>
                  {" "}
                  {application?.firstname} Muraho! BDF inejejwe no kukumenyesha ko inkunga nyunganizi mwasabye kugirango ibafashe guteza imbere ibikorwa byanyu bijyanye no kongera umusaruro w’ubuhinzi/ubworozi mwayemerewe. 
                  Murasabwa kugana ishami rya BDF ribegereye kugirango musinye amasezerano ajyanye n’imikoreshereze y’iyo nkunga.
                  BDF ibifurije ishya n’ihirwe.                  
                </p>
              </div>
            </div>
            <div
              className={
                submitt &&
                application !== null &&
                application?.icdecision === "REJECTED"
                  ? "d-show"
                  : "d-none"
              }
            >
              <div className={"alert alert-info text-center pt-3"} role="alert">
                <p>
                  {" "}
                  {application?.firstname} Muraho! BDF irakumenyesha ko  yasuzumye ubusabe bwanyu ku nkunga nyunganizi mu mushinga CDAT isanga butujuje ibisabwa, bityo ikaba iboneyeho kukumenyesha ko inkunga mwasabye mutayemerewe. Ku bindi bisobanuro ndetse n’icyatumye ubusabe bwanyu butemerwa tuzabibagezaho mu ibaruwa muzasanga ku ishami rya BDF ribegereye. 
                  Mugire Amahoro 
                  </p>
              </div>
            </div>
            <div
              className={
                submitt &&
                application !== null &&
                application?.icdecision === "PENDING"
                  ? "d-show"
                  : "d-none"
              }
            >
              <div
                className={"alert alert-secondary text-center pt-3"}
                role="alert"
              >
              <p>
              {" "}
              {application?.firstname} Muraho! Umushinga mwatanze urikwigwaho.           
            </p>
              </div>
            </div>
            <div className="d-none">
              <NavLink to="/terms-and-conditions">
                Read Terms and Conditons / Soma amategeko n'amabwiriza
              </NavLink>
            </div>
            <div className="pt-2">
              <div className="row d-flex justify-content-center">
                <div className="col-md-2 d-flex justify-content-center">
                  <div>
                    <img alt="img" src={minagri} className="img-fluid h-25" />
                    <div className="text-center">
                      <span>MINAGRI</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 d-flex justify-content-center">
                  <img alt="img" src={rab} className="img-fluid h-25" />
                </div>
                <div className="col-md-2 text-center">
                  <img alt="img" src={bdf} className="h-25" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResultPage;
