import { combineReducers } from "redux";
import periodsReducer from "../reducers/periods";
import authReducer from "../reducers/auth";
import applicationsReducer from "../reducers/applications";
import cdatReducer from "../reducers/cdat";

export default combineReducers({
  periods: periodsReducer,
  auth: authReducer,
  applications: applicationsReducer,
  cdat: cdatReducer,
});
