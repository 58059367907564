import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import constants from "../components/constants";
import Header from "../components/header";
import {
  ActivateButton,
  EditButton,
  InputText2,
  InputTextOC,
  SelectInput,
  SelectInputCriteria,
  SelectInputCriteria2nd,
  SubmitButton,
} from "../components/inputs";
import MiSpinner, {
  Beadcrumb,
  CountDown,
  formatDecimals,
  ObjectDetails,
  PageTitle,
} from "../components/utils";
import { getActiveCriterias, getCriteriaValuesSubs } from "../store/actions";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import {
  createApplications,
  getApplicationCriteriasSuccesInit,
  getApplicationsSuccesInit,
} from "../store/actions/applications";
import {
  errorNotificationDataMissing,
  successApplyingNotification,
  successCreationNotification,
} from "../components/notifications";
import { ReactNotifications } from "react-notifications-component";
import { Provinces, Districts, Sectors } from "rwanda";

function CriteriasPage() {
  const WORKSPACE =
    "SELECT WHAT APPLIES TO YOUR PROJECT/HITAMO IBISOBANURA NEZA UMUSHINGA WAWE";
  const [clicked, setclicked] = useState();
  const [valuess, setvaluess] = useState([]);
  const [childvalues, setchildvalues] = useState([]);
  const [criterias, setcriterias] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const deadline = useSelector(
    (state) => state.periods?.activePeriod?.closingdate
  );

  const objectsCriteria = useSelector((state) => state.cdat.criterias);
  const objectsCriteriaLoading = useSelector(
    (state) => state.cdat.criteriasLoading
  );
  const theApplication = useSelector((state) => state.applications.application);
  const subcriteria = useSelector((state) => state.cdat.criteriaValueSubs);
  const [projectname, setprojectname] = useState("");
  const [owncontribution, setowncontribution] = useState("");
  const [totalinvestment, settotalinvestment] = useState("");
  const [projprovince, setprojProvince] = useState("");
  const [projdistrict, setprojDistrict] = useState("");
  const [projsector, setprojSector] = useState("");
  const [districts, setDistricts] = useState([]);
  const [sectors, setSectors] = useState([]);

  const [amtError, setamtError] = useState("false");

  const [submitted, setsubmitted] = useState(false);

  const [zeval, setzeval] = useState();

  const applicationError = useSelector(
    (state) => state.applications.applicationsFail
  );

  const [thecriteriaTitle, setthecriteriaTitle] = useState(
    "Select /Hitamo "
  );

  const arrayToOptionsHandler = (arr = []) => {
    return arr.map((element) => {
      const item = {};
      item.value = element;
      if (element === "East") {
        item.name = element + " | Uburasirazuba";
      } else if (element === "West") {
        item.name = element + " | Uburengerazuba";
      } else if (element === "North") {
        item.name = element + " | Amajyaruguru";
      } else if (element === "South") {
        item.name = element + " | Amajyepfo";
      } else if (element === "Kigali") {
        item.name = element + " City | Umujyi wa Kigali";
      }
      else {
        item.name = element;
      }

      return item;
    });
  };

  const setTheProvince = (value) => {
    setprojProvince(value);
    setDistricts(arrayToOptionsHandler(Districts(value)));
    setSectors([]);
    setclicked(false);
  };
  const setTheDistrict = (value) => {
    setprojDistrict(value);
    setSectors(arrayToOptionsHandler(Sectors(projprovince, value)));
    setclicked(false);
  };

  const application = {
    ...theApplication,
    owncontribution: owncontribution.replace(/\,/g, ""),
    totalinvestment: totalinvestment.replace(/\,/g, ""),
    projectname,
    projdistrict,
    projprovince,
    projsector,
  };

  const [show, setShow] = useState(false);

  const handleClose = (e) => {
    e.preventDefault();
    setShow(false);
  };

  useEffect(() => {
    dispatch(getActiveCriterias());
    if (theApplication === null && submitted === false) {
      history.push("/personal-details");
    }
    if (submitted === true) {
      if (theApplication?.uuid !== undefined && theApplication?.uuid !== null) {
        history.push("/last-page");
      } else {
        errorNotificationDataMissing("APPLICATION");
        history.push("/application");
      }
    }
  }, [theApplication]);

  const onPreview = (e) => {
    e.preventDefault();
    //  getApplicationCriteriasSuccesInit(criterias);
    const grantreq = Number(owncontribution.replace(/\,/g, ""));
    const totinvestment = Number(totalinvestment.replace(/\,/g, ""));
    const half = totinvestment / 2;
    if (
      owncontribution.trim() === "" ||
      totalinvestment.trim() === "" ||
      projectname.trim() === "" ||
      grantreq > totinvestment ||
      grantreq > 100000000 ||
      grantreq > half ||
      grantreq <= 0
    ) {
      setclicked(true);
    } else if (criterias.length !== objectsCriteria.length + 2) {
      errorNotificationDataMissing("Application");
      setclicked(true);
    } else {
      setclicked(false);
      setShow(true);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let resp = "true";
    criterias?.forEach((item) => {
      if (
        item?.criteriaId === "" ||
        item?.criteriaValueId === "" ||
        item?.criteriaId === undefined ||
        item?.criteriaValueId === undefined
      ) {
        resp = "false";
      }
    });
    if (resp === "true") {
      const object = {
        applicant: application,
        criterias,
      };
      // console.log(object);
      dispatch(createApplications(object));
      setsubmitted(true);
      dispatch(getActiveCriterias());
      setShow(false);
    } else {
      errorNotificationDataMissing("Application");
      setclicked(true);
    }
  };

  const back = (e) => {
    e.preventDefault();
    dispatch(getApplicationsSuccesInit(application));
    history.push("/personal-details");
  };

  // USEREFFFF
  const refs = useRef([]);
  refs.current = [];

  const addToRefs = (ref) => {
    if (ref && !refs.current.includes(ref)) {
      refs.current.push(ref);
    }
  };

  let responses = [];
  const testsubmission = (e) => {
    refs.current.forEach((element) => {
      // console.log(element.submit());
      if (Array.isArray(element.submit())) {
        responses.push(element.submit()[0]);
        responses.push(element.submit()[1]);
      } else {
        responses.push(element.submit());
      }
    });
    e.preventDefault();
    setcriterias(responses);
    //  getApplicationCriteriasSuccesInit(criterias);
    const grantreq = Number(owncontribution.replace(/\,/g, ""));
    const totinvestment = Number(totalinvestment.replace(/\,/g, ""));
    const half = totinvestment / 2;
    if (
      owncontribution.trim() === "" ||
      totalinvestment.trim() === "" ||
      projectname.trim() === "" ||
      grantreq > totinvestment ||
      grantreq > 100000000 ||
      grantreq > half ||
      grantreq <= 0
    ) {
      setclicked(true);
    } else if (responses.length !== objectsCriteria.length + 3) {
      // errorNotificationDataMissing("Application");
      setclicked(true);
    } else {
      setclicked(false);
      setShow(true);
    }
  };

  return (
    <>
      <Header />
      <ReactNotifications />
      <div className="container">
        <Modal show={show} onHide={handleClose} size="xl">
          <Modal.Body>
            <div className="row px-5 pt-3 pb-5">
              <h6 className="text-primary text-center pb-3">
                Umwirondoro/Application Details
              </h6>
              <div className="col-sm-12 col-md-4">
                <ObjectDetails
                  appltype={application?.applicanttype}
                  title={"Amazina/Names"}
                  value={application?.firstname + " " + application?.lastname}
                />
                <ObjectDetails
                  title={"Project Name/Umushinga"}
                  value={application?.projectname}
                />
                <ObjectDetails
                  title={"Total Investment/Igishoro cyose"}
                  value={formatDecimals(application?.totalinvestment)}
                />
                <ObjectDetails
                  title={"Desired Grant/Inkunga ukeneye"}
                  value={formatDecimals(application?.owncontribution)}
                />
                <div
                  className={
                    application?.applicanttype !== "individual"
                      ? "d-show"
                      : "d-none"
                  }
                >
                  <ObjectDetails
                    title={"Company Name"}
                    value={application?.companyname}
                  />
                  <ObjectDetails
                    title={"TIN/RCA Index"}
                    value={application?.tin}
                  />
                </div>
                <div
                  className={
                    application?.applicanttype === "individual"
                      ? "d-show"
                      : "d-none"
                  }
                >
                  <ObjectDetails
                    title={"Igitsina/Gender"}
                    value={application?.gender}
                  />
                  <ObjectDetails
                    title={"Irangamuntu/National ID"}
                    value={application?.nationalId}
                  />
                  <ObjectDetails
                    title={"Date of birth/Italiki y'amavuko"}
                    value={application?.dob}
                  />
                </div>
                <ObjectDetails
                  title={"Telefoni/Telephone"}
                  value={application?.telephone}
                />
                <ObjectDetails
                  title={"Imeyiri/Email"}
                  value={application?.email}
                />

                <div className="pt-2">
                  <span>Applicant Location/Aho usaba abarizwa</span>
                  <ObjectDetails
                    title={"Intara/Province"}
                    value={application?.province}
                  />
                  <ObjectDetails
                    title={"Akarere/District"}
                    value={application?.district}
                  />
                  <ObjectDetails
                    title={"Sector/Umurenge"}
                    value={application?.sector}
                  />
                </div>
                <div className="pt-2">
                  <span>Project location/ Aho umushinga ubarizwa</span>
                  <ObjectDetails
                    title={"Akarere/District"}
                    value={application?.projprovince}
                  />
                  <ObjectDetails
                    title={"Akarere/District"}
                    value={application?.projdistrict}
                  />
                  <ObjectDetails
                    title={"Sector/Umurenge"}
                    value={application?.projsector}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-8">
                {criterias?.map((data, index) => (
                  <ObjectDetails
                    key={index}
                    title={data?.criteria}
                    value={data?.criteriaValue}
                  />
                ))}
                <div className="mb-3"></div>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary text-white"
                onClick={handleClose}
              >
                Hindura/Edit
              </button>{" "}
              <span className="px-3"></span>
              <button className="btn btn-success text-white" onClick={onSubmit}>
                {"Ohereza/Submit"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <Beadcrumb />
        <div className="pt-5">
          <PageTitle pagetitle={WORKSPACE} />
        </div>
        <div id="contact" className="input-data">
          <div className="" data-aos="fade-up">
            <div className="row d-flex php-email-form">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-sm-10 col-md-4">
                    <div className="form-group py-1">
                      <InputText2
                        title={"Project name/Inyito y’umushinga"}
                        value={projectname}
                        name="projectname"
                        placeholder={"Project name/Inyito y’umushinga"}
                        isClicked={clicked}
                        onChange={(e) => {
                          setprojectname(e.target.value);
                          setclicked(false);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-10 col-md-4">
                    <div className="form-group py-1">
                      <InputText2
                        inptype="number"
                        title={"Total investment/Ingano y’igishoro (Frw)"}
                        value={totalinvestment}
                        name="totalinvestment"
                        placeholder={"Total investment/Ingano y’igishoro"}
                        isClicked={clicked}
                        onChange={(e) => {
                          settotalinvestment(e.target.value);
                          setclicked(false);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-10 col-md-4">
                    <div className="form-group py-1">
                      <InputText2
                        inpvalue="grant"
                        inptype="number"
                        title={"Desired Grant/Inkunga ukeneye (Frw)"}
                        value={owncontribution}
                        totalinvestment={totalinvestment}
                        name="owncontribution"
                        placeholder={"Desired Grant/Inkunga ukeneye"}
                        isClicked={clicked}
                        onChange={(e) => {
                          setowncontribution(e.target.value);
                          setclicked(false);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row d-flex">
                  <label className="pt-3 bdf-bluefontcolor col-md-3">
                    PROJECT LOCATION / AHO UMUSHINGA UBARIZWA
                  </label>
                  <div className="row col-md-9">
                    <div className="col-md-4">
                      <SelectInput
                        title="Province/Intara"
                        startingValue={"HITAMO/SELECT"}
                        options={arrayToOptionsHandler(Provinces())}
                        value={projprovince}
                        changed={setTheProvince}
                        isClicked={clicked}
                      />
                    </div>
                    <div className="col-md-4">
                      <SelectInput
                        title="District/Akarere"
                        startingValue={"HITAMO/SELECT"}
                        options={districts}
                        value={projdistrict}
                        changed={setTheDistrict}
                        isClicked={clicked}
                      />
                    </div>
                    <div className="col-md-4">
                      <SelectInput
                        title="Sector/Umurenge"
                        startingValue={"HITAMO/SELECT"}
                        options={sectors}
                        value={projsector}
                        changed={setprojSector}
                        isClicked={clicked}
                      />
                    </div>
                  </div>
                </div>
                <div className="row d-flex">
                  {objectsCriteriaLoading ? (
                    <>
                      <div className="w-100 text-center">
                        <MiSpinner />
                      </div>
                    </>
                  ) : (
                    <>
                      {objectsCriteria?.map((object, index) => (
                        <div
                          className={
                            object?.haschildren === "true"
                              ? "col-sm-10 col-md-12"
                              : "col-sm-10 col-md-6"
                          }
                          key={index}
                        >
                          <div className="form-group py-1">
                            <SelectInputCriteria2nd
                              ref={addToRefs}
                              key={index}
                              id={index}
                              title={object?.criteria}
                              haschildren={object?.haschildren}
                              startingValue={"HITAMO/SELECT"}
                              options={object?.criteriaValues}
                              value={valuess[index + 1]}
                              childvalue={childvalues[index + 1]}
                              changed={()=>{}}
                              childchanged={()=>{}}
                              criteriaId={object?.id}
                              criteria={object?.criteria}
                              isClicked={clicked}
                              subcriteria={[]}
                              subcriteriass={object}
                              subcriteriassi={
                                object.criteriaValues[index]?.subCriteriaValues
                              }
                              criteriaTitle={thecriteriaTitle}
                            />
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
                <div className="pt-3">
                  <div className="row gx-5"></div>
                  <SubmitButton action={"Back/Subira Inyuma"} onSubmit={back} />
                  <span className="px-2"></span>
                  <SubmitButton
                    classes="bg-success"
                    action={"Continue/Komeza"}
                    onSubmit={testsubmission}
                  />
                </div>
              </div>
              <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CriteriasPage;
