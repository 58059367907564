import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import AdminHeader from "../../components/adminheader";
import * as actions from "../../store/actions";
import moment from "moment";
import {
  ActivateButton,
  EditButton,
  InputDate,
  InputNationalId,
  InputText2,
  PageLink,
  SelectInput,
  SelectRadioButton,
  SubmitButton,
  ViewButton,
} from "../../components/inputs";
import constants from "../../components/constants";
import { Beadcrumb, PageTitle, formatDecimals } from "../../components/utils";
import { useDispatch, useSelector } from "react-redux";
import { ReactNotifications } from "react-notifications-component";
import {
  successCreationNotification,
  successUpdateNotification,
} from "../../components/notifications";

export const AdminHome = () => {
  const WORKSPACE = "Application Calls";
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const object = useSelector((state) => state.periods.period);
  const [clicked, setclicked] = useState(false);
  const [passmark, setpassmark] = useState("");
  const [maxceiling, setmaxceiling] = useState("");
  const [openingdate, setopeningdate] = useState("");
  const [closingdate, setclosingdate] = useState("");
  const [publishingdate, setpublishingdate] = useState("");
  const [projectname, setprojectname] = useState("");
  const [closingdateErr, setclosingdateErr] = useState("");
  const [publishingdateErr, setpublishingdateErr] = useState("");
  const [allowoldapplicants, setallowoldapplicants] = useState("");

  const [waitingmarks, setwaitingmarks] = useState(0);
  const [rejectionmarks, setrejectionmarks] = useState(0);

  const token = useSelector((state) => state.auth.token);
  const userNames = useSelector((state) => state.auth.displayName);

  const onSubmit = (e) => {
    e.preventDefault();
    const now = new Date().getTime();
    const opd = new Date(openingdate).getTime();
    const cld = new Date(closingdate).getTime();
    const pubd = new Date(publishingdate).getTime();
    setclosingdateErr("");
    setpublishingdateErr("");
    if (
      passmark === "" ||
      openingdate === "" ||
      projectname === "" ||
      closingdate === "" ||
      publishingdate === "" ||
      allowoldapplicants === ""
    ) {
      setclicked(true);
    } else if (now > opd || opd > cld || cld > pubd) {
      setclicked(true);
      if (now > opd) {
        alert("Opening Date Can not be in the past");
        // setclosingdateErr("Closing Date Can not be Before Opening Date");
      }
      if (opd > cld) {
        alert("Closing Date Can not be Before Opening Date");
        // setclosingdateErr("Closing Date Can not be Before Opening Date");
      }
      if (cld > pubd) {
        alert("Publishing Date Can not be Before Closing Date");
        //  setpublishingdateErr("Publishing Date Can not be Before Closing Date");
      }
    } else {
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const allow = allowoldapplicants?.target?.value;
          const obj = {
            passmark,
            openingdate,
            maxceiling: maxceiling.replace(/\,/g, ""),
            projectname,
            closingdate,
            publishingdate,
            allowoldapplicants: allow,
            waitingmarks,
            rejectionmarks,
            id: object?.uuid,
          };
          dispatch(actions.updatePeriods(token, obj));
          setpassmark("");
          setopeningdate("");
          setclosingdate("");
          setpublishingdate("");
          setmaxceiling("");
          setprojectname("");
          setclosingdateErr("");
          setpublishingdateErr("");
          setallowoldapplicants("");
          setwaitingmarks("");
          setrejectionmarks("");
          setclicked(false);
          successUpdateNotification(WORKSPACE, "Updated");
        } else {
        }
      } else {
        if (window.confirm("Are you sure you want submit?")) {
          const allow = allowoldapplicants?.target?.value;
          const obj = {
            passmark,
            maxceiling: maxceiling.replace(/\,/g, ""),
            openingdate,
            projectname,
            closingdate,
            publishingdate,
            allowoldapplicants: allow,
            creator: userNames,
          };
          dispatch(actions.createPeriods(obj, token));
          setpassmark("");
          setopeningdate("");
          setclosingdate("");
          setpublishingdate("");
          setprojectname("");
          setmaxceiling("");
          setclosingdateErr("");
          setpublishingdateErr("");
          setallowoldapplicants("");
          setclicked(false);
          successCreationNotification("Application Period");
        } else {
        }
      }
    }
  };

  const activating = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want change the status?")) {
      if (object?.status === constants.active) {
        dispatch(actions.desActivatingPeriods(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Inactive");
      } else {
        dispatch(actions.activatingPeriods(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Active");
      }
    } else {
    }
  };

  useEffect(() => {
    if (params?.objectId && object === null) {
      dispatch(actions.getOnePeriods(token, params?.objectId));
    }
    if (object !== null) {
      setpassmark(object?.passmark);
      setwaitingmarks(object?.waitingmarks);
      setrejectionmarks(object?.rejectionmarks);
      setprojectname(object?.projectname);
      setclosingdate(moment(object?.closingdate).format("YYYY-MM-DD"));
      setopeningdate(moment(object?.openingdate).format("YYYY-MM-DD"));
      setpublishingdate(moment(object?.publishingdate).format("YYYY-MM-DD"));
    }
  }, [object]);

  return (
    <>
      <AdminHeader />
      <ReactNotifications />
      <div className="container">
        <Beadcrumb />
        <div className="py-3"></div>
        <PageTitle pagetitle={WORKSPACE} />
        <div id="contact" className="input-data">
          <div className="" data-aos="fade-up">
            <div className="row d-flex php-publishingdate-form">
              <div className="col-md-9">
                <div className="row">
                  <div className="col-sm-10 col-md-6">
                    <div className="form-group py-1">
                      <InputText2
                        title={"Project Name"}
                        value={projectname}
                        name="projectname"
                        placeholder={t("Project Name")}
                        isClicked={clicked}
                        onChange={(e) => setprojectname(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-sm-10 col-md-6">
                    <div className="form-group py-1">
                      <InputText2
                        inptype="number"
                        title={"Maxmum Ceiling/Inkunga ihari (Frw)"}
                        value={maxceiling}
                        name="maxceiling"
                        placeholder={"Maxmum Ceiling/Inkunga ihari"}
                        isClicked={clicked}
                        onChange={(e) => {
                          setmaxceiling(e.target.value);
                          setclicked(false);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-10 col-md-6">
                    <div className="form-group py-1">
                      <InputText2
                        title={"Pass Mark"}
                        value={passmark}
                        name="passmark"
                        placeholder={t("Pass Mark")}
                        isClicked={clicked}
                        onChange={(e) => setpassmark(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className={object !== null ? "row" : "d-none"}>
                  <div className="col-sm-10 col-md-6">
                    <div className="form-group py-1">
                      <InputText2
                        title={"Waiting Marks"}
                        value={waitingmarks}
                        name="waitingmarks"
                        placeholder={t("Waiting marks")}
                        isClicked={clicked}
                        onChange={(e) => setwaitingmarks(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-sm-10 col-md-6">
                    <div className="form-group py-1">
                      <InputText2
                        title={"Rejecting Marks"}
                        value={rejectionmarks}
                        name="rejectionmarks"
                        placeholder={t("Pass Mark")}
                        isClicked={clicked}
                        onChange={(e) => setrejectionmarks(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row d-flex">
                  <div className="col-sm-10 col-md-6">
                    <div className="form-group py-1">
                      <InputDate
                        title={"Opening Date"}
                        value={openingdate}
                        name="openingdate"
                        placeholder={t("Opening Date")}
                        isClicked={clicked}
                        onChange={(e) => setopeningdate(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-sm-10 col-md-6">
                    <div className="form-group py-1">
                      <InputDate
                        title={"Closing Date"}
                        value={closingdate}
                        name="closingdate"
                        placeholder={t("Closing Date")}
                        isClicked={clicked}
                        onChange={(e) => setclosingdate(e.target.value)}
                      />
                      <span className="text-danger">{closingdateErr}</span>
                    </div>
                  </div>
                </div>

                <div className="row d-flex">
                  <div className="col-sm-10 col-md-6">
                    <div className="form-group py-1">
                      <InputDate
                        title={"Publishing Result Date"}
                        value={publishingdate}
                        name="publishingdate"
                        placeholder={t("Publishing Result Date")}
                        isClicked={clicked}
                        onChange={(e) => setpublishingdate(e.target.value)}
                      />
                      <span className="text-danger">{publishingdateErr}</span>
                    </div>
                  </div>
                  <div className="col-sm-10 col-md-6"></div>
                </div>

                <div className="row d-flex">
                  <div className="col-sm-10 col-md-6">
                    <div className="form-group py-1">
                      <SelectRadioButton
                        title="Allow Old Applicants"
                        option1="true"
                        option2="false"
                        isClicked={clicked}
                        value={allowoldapplicants}
                        name={allowoldapplicants}
                        onChange={setallowoldapplicants}
                      />
                      <span className="text-danger">{publishingdateErr}</span>
                    </div>
                  </div>
                  <div className="col-sm-10 col-md-6"></div>
                </div>

                <div className="pt-3">
                  <div className="row gx-5"></div>
                  <SubmitButton
                    classes={"btn btn-primary btn-primary"}
                    action={object ? "Update" : "Create"}
                    onSubmit={onSubmit}
                  />
                  <span className="px-2"></span>
                  <ActivateButton
                    status={object?.status}
                    onSubmit={activating}
                  />
                </div>
              </div>
              <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                <div>
                  <div className="flex flex-column h-50">
                    <PageLink page={"/periods-objects-list"} title={"List"} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const PeriodsList = () => {
  const WORKSPACE = "Application Calls";
  const loading = useSelector((state) => state.periods.periodsLoading);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const object = useSelector((state) => state.periods.period);
  const objectList = useSelector((state) => state.periods.periods);

  useEffect(() => {
    dispatch(actions.getPeriods());
  }, []);

  const [show, setShow] = useState(false);
  const handleClose = (e) => {
    e.preventDefault();
    dispatch(actions.getPeriods());
    setShow(false);
  };

  const viewMore = (id) => {
    dispatch(actions.getOnePeriods(token, id));
    setShow(true);
  };

  return (
    <div className="">
      <AdminHeader />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <span>LOADING...</span>
        ) : (
          <>
            <section id="contact" className="input-data">
              <div className="container" data-aos="fade-up">
                <div className="row d-flex justify-content-end php-email-form">
                  <div className="col-md-10">
                    <div className="text-center">
                      <h5 className="bdf-bluefontcolor">{WORKSPACE} LIST</h5>
                    </div>
                    <table className="table table-striped text-center">
                      <thead>
                        <tr className="bdf-bluefontcolor">
                          <th>#</th>
                          <th>Project</th>
                          <th>Allow Old Appl</th>
                          <th>Status</th>
                          <th>Max Ceiling</th>
                          <th>Grant Left</th>
                          <th>PassMark</th>
                          <th>Open</th>
                          <th>Close</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody className="">
                        {objectList?.map((data, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{data?.projectname}</td>
                            <td>{data?.allowoldapplicants}</td>
                            <td>{data?.status}</td>
                            <td>{formatDecimals(data?.maxceiling + "")}</td>
                            <td>
                              {formatDecimals(data?.remainingamount + "")}
                            </td>
                            <td>{data?.passmark}</td>
                            <td>
                              {moment(data?.openingdate).format("DD-MM-YYYY")}
                            </td>
                            <td>
                              {moment(data?.closingdate).format("DD-MM-YYYY")}
                            </td>
                            <td className="row d-flex justify-content-center">
                              <EditButton
                                title="Edit"
                                page="/admin-home"
                                id={data?.uuid}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-2 d-flex gy-5 ml-5 justify-content-end">
                    <div>
                      <div className="flex flex-column h-50">
                        <PageLink page="/admin-home" title={"New Period"} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </main>
    </div>
  );
};
