import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import AdminHeader from "../../components/adminheader";
import * as actions from "../../store/actions";
import moment from "moment";
import {
  ActivateButton,
  EditButton,
  InputDate,
  InputNationalId,
  InputText2,
  InputTextSecret,
  InputTextSecret2,
  PageLink,
  SelectInput,
  SelectRadioButton,
  SubmitButton,
  ViewButton,
} from "../../components/inputs";
import constants from "../../components/constants";
import {
  Beadcrumb,
  ObjectDetails,
  PageTitle,
  formatDecimals,
} from "../../components/utils";
import { useDispatch, useSelector } from "react-redux";
import { ReactNotifications } from "react-notifications-component";
import { successUpdateNotification, updatePasswordNotification } from "../../components/notifications";

export const AdminProfile = () => {
  const WORKSPACE = "USER PROFILE";
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const object = useSelector((state) => state.auth);
  const [clicked, setclicked] = useState(false);
  const [cfrmnewpswd, setcfrmnewpswd] = useState("");
  const [newpswd, setnewpswd] = useState("");

  const token = useSelector((state) => state.auth.token);
  const userNames = useSelector((state) => state.auth.displayName);
  const theUser = useSelector((state) => state.auth);

  const onSubmit = (e) => {
    e.preventDefault();
    if (newpswd === "" || newpswd.trim() === "" || newpswd !== cfrmnewpswd) {
      console.log("Password mismatch");
    } else {
      if (window.confirm("Are you sure you want change password?")) {
        const obj = { 
          password:newpswd,
          id: object?.userId,
        };
        dispatch(actions.changePassword(obj, token));
        updatePasswordNotification();
        setnewpswd("");
        setcfrmnewpswd("");
      } else {
      }
    }
  };

  useEffect(() => {
    if (object !== null) {
      setnewpswd(object?.newpswd);
      setcfrmnewpswd(object?.newpswd);
    }
  }, [object]);

  return (
    <>
      <AdminHeader />
      <ReactNotifications />
      <div className="container">
        <Beadcrumb />
        <div className="py-3"></div>
        <PageTitle pagetitle={WORKSPACE} />
        <div id="contact" className="input-data">
          <div className="" data-aos="fade-up">
            <div className="row d-flex php-publishingdate-form p-5">
              <div className="col-md-9">
                <div className="row">
                  <ObjectDetails title="Names" value={userNames} />
                  <ObjectDetails title="Username" value={theUser?.username} />
                  <ObjectDetails title="Telephone" value={theUser?.telephone} />
                  <ObjectDetails title="Title" value={theUser?.title} />
                </div>
                <div className="pt-5 pb-5">
                  <div className="bdf-blueline"></div>
                </div>
                <div className="pt-3">
                  <div className="row gx-5"></div>
                  <div className="row pb-3">
                    <div className="col-sm-10 col-md-6">
                      <div className="form-group py-1">
                        <span className="text-primary">New Password</span>
                        <InputTextSecret
                          title={"New Password"}
                          value={newpswd}
                          name="newpswd"
                          placeholder={t("New Password")}
                          isClicked={clicked}
                          onChange={(e) => setnewpswd(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-10 col-md-6">
                      <div className="form-group py-1">
                        <span className="text-primary">Confirm Password</span>
                        <InputTextSecret
                          title={"Confirm Password"}
                          value={cfrmnewpswd}
                          name="cfrmnewpswd"
                          placeholder={t("Confirm Password")}
                          onChange={(e) => setcfrmnewpswd(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <SubmitButton
                    classes={"btn btn-primary btn-primary"}
                    action={"Reset Password"}
                    onSubmit={onSubmit}
                  />
                </div>
              </div>
              <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end d-none">
                <div>
                  <div className="flex flex-column h-50">
                    <PageLink page={"/periods-objects-list"} title={"List"} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
