import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import AdminHeader from "../../components/adminheader";
import * as actions from "../../store/actions";
import moment from "moment";
import {
  ActivateButton,
  EditButton,
  InputNationalId,
  InputText2,
  InputTextSearch,
  PageLink,
  SelectInput,
  SubmitButton,
  ViewButton,
  ViewContentBtn,
} from "../../components/inputs";
import constants from "../../components/constants";
import {
  Beadcrumb,
  ObjectDetails,
  PageTitle,
  formatDecimals,
} from "../../components/utils";
import { useDispatch, useSelector } from "react-redux";
import { ReactNotifications } from "react-notifications-component";
import Modal from "react-bootstrap/Modal";
import {
  sendingLettersNotification,
  waitPublishingDate,
} from "../../components/notifications";
import * as XLSX from "xlsx/xlsx.mjs";

export const ApplicationsList = () => {
  const WORKSPACE = "Applicants";
  const history = useHistory();
  const loading = useSelector(
    (state) => state.applications.applicationsLoading
  );
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const period = useSelector((state) => state.periods?.activePeriod);
  const application = useSelector((state) => state.applications.application);
  const objectList = useSelector((state) => state.applications.applications);

  useEffect(() => {
    dispatch(actions.getApplications());
    dispatch(actions.getActivePeriods());
  }, []);

  const [searchkey, setsearchkey] = useState("");
  const [searchvalue, setsearchvalue] = useState("");
  const [show, setShow] = useState(false);
  const [clicked, setclicked] = useState(false);
  const setTheSearchkey = (value) => {
    setsearchkey(value);
    setclicked(false);
  };
  const handleClose = (e) => {
    e.preventDefault();
    dispatch(actions.getApplications());
    setShow(false);
  };

  const viewMore = (id) => {
    dispatch(actions.getOneApplications(token, id));
    setShow(true);
  };

  const sendLetters = (e) => {
    e.preventDefault();
    history.push("/send-letters")
  };

  const searchfn = () => {
    if (searchkey.trim() === "" || searchvalue.trim() === "") {
      setclicked(true);
    } else {
      dispatch(actions.getApplicationsBySearching(searchkey, searchvalue));
    }
  };

  const exportToExcel = () => {
    if (objectList.length > 0) {
      let wb = XLSX.utils.book_new(),
        ws = XLSX.utils.json_to_sheet(objectList);
      XLSX.utils.book_append_sheet(wb, ws, "Applications");
      XLSX.writeFile(wb, "Applications.xlsx");
    } else {
      alert("There is no any object in the list")
    }
  };

  return (
    <div className="">
      <AdminHeader />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <span>LOADING...</span>
        ) : (
          <>
            <Modal show={show} onHide={handleClose} size="xl">
              <Modal.Body>
                <div className="row px-5 pt-3 pb-5">
                  <h6 className="text-primary text-center pb-3">
                    Umwirondoro/Application Details
                  </h6>

                  <div className="col-sm-12 col-md-4">
                    <ObjectDetails
                      title={"Amazina/Names"}
                      value={
                        application?.firstname + " " + application?.lastname
                      }
                    />
                    <ObjectDetails
                      title={"Application ID"}
                      value={application?.applicationcode}
                    />
                    <ObjectDetails
                      title={"Igitsina/Gender"}
                      value={application?.gender ?? application?.applicanttype}
                    />
                    <ObjectDetails
                      title={"Irangamuntu/National ID"}
                      value={application?.nationalId ?? application?.tin}
                    />
                    <ObjectDetails
                      title={"Telefoni/Telephone"}
                      value={application?.telephone}
                    />
                    <ObjectDetails
                      title={"Imeyiri/Email"}
                      value={application?.email}
                    />
                    <ObjectDetails
                      title={"Intara/Province"}
                      value={application?.province}
                    />
                    <ObjectDetails
                      title={"Akarere/District"}
                      value={application?.district}
                    />
                    <ObjectDetails
                      title={"Sector/Umurenge"}
                      value={application?.sector}
                    />
                    <ObjectDetails
                      title={"Amanota/Score"}
                      value={application?.applicationscore}
                    />
                  </div>
                  <div className="col-sm-12 col-md-8">
                    {application?.criterias.map((data, index) => (
                      <ObjectDetails
                        key={index}
                        title={data?.criteria}
                        value={data?.criteriaValue}
                      />
                    ))}
                    <div className="mb-3"></div>
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-primary text-white"
                    onClick={handleClose}
                  >
                    Close
                  </button>{" "}
                  <span className="px-3"></span>
                </div>
              </Modal.Body>
            </Modal>
            <section id="contact" className="input-data">
              <div className="container" data-aos="fade-up">
                <div className="row d-flex php-email-form">
                  <div className="col-md-3 pt-1">
                    <div className="form-group py-1 col-md-8">
                      <SelectInput
                        title="Search Key Type"
                        startingValue={"HITAMO/SELECT"}
                        options={constants.searchkeys}
                        value={searchkey}
                        changed={setsearchkey}
                        isClicked={clicked}
                      />
                    </div>
                    <div>
                      <div className="col-md-10">
                        <div className="form-group py-1">
                          <InputTextSearch
                            title=""
                            value={searchvalue}
                            name="searchvalue"
                            placeholder={"Search value"}
                            isClicked={clicked}
                            onChange={(e) => {
                              setsearchvalue(e.target.value);
                              setclicked(false);
                            }}
                          />
                          <ViewButton title="Search" onClick={searchfn} />
                        </div>
                      </div>
                    </div>

                    <div className="d-flex gy-5 pt-5">
                      <div className="flex flex-column h-50">
                        <button
                          className="btn btn-primary text-white"
                          onClick={sendLetters}
                        >
                          Send Letters
                        </button>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="text-center">
                      <h5 className="bdf-bluefontcolor">{WORKSPACE} LIST</h5>
                    </div>
                    <div
                      className={
                        objectList?.length <= 0
                          ? "alert alert-danger text-center"
                          : "d-none"
                      }
                      role="alert"
                    >
                      There Are No Applications Found
                    </div>
                    <div className="justify-content-end">
                      <span
                        onClick={exportToExcel}
                        className="bg-success btn text-white px-2 py-1 view-more"
                      >
                        <i class="bi bi-file-excel"></i> Excel
                      </span>
                    </div>
                    <table className="table table-striped text-center">
                      <thead>
                        <tr className="bdf-bluefontcolor">
                          <th>#</th>
                          <th>Applicant</th>
                          <th>Code</th>
                          <th>Aplicant Type</th>
                          <th>Grant Request</th>
                          <th>Gender</th>
                          <th>Applied At</th>
                          <th>Score</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody className="">
                        {objectList?.map((data, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{data?.firstname + " " + data?.lastname}</td>
                            <td>{data?.applicationcode}</td>
                            <td>{data?.applicanttype}</td>
                            <td>
                              {formatDecimals(data?.owncontribution + "")}
                            </td>
                            <td>{data?.gender}</td>
                            <td>
                              {moment(data?.createdAt).format(
                                "DD/MM/YYYY hh:mm"
                              )}
                            </td>
                            <td>{data?.applicationscore}</td>
                            <td className="row d-flex justify-content-center">
                              <ViewButton
                                title={"View "}
                                onClick={(e) => {
                                  e.preventDefault();
                                  viewMore(data?.uuid);
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </main>
    </div>
  );
};
