import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import AdminHeader from "../../components/adminheader";
import * as actions from "../../store/actions";
import moment from "moment";
import {
  ActivateButton,
  EditButton,
  InputNationalId,
  InputText2,
  PageLink,
  SelectInput,
  SelectRadioButton,
  SubmitButton,
  ViewButton,
  ViewContentBtn,
} from "../../components/inputs";
import constants from "../../components/constants";
import { Beadcrumb, PageTitle } from "../../components/utils";
import { useDispatch, useSelector } from "react-redux";
import { ReactNotifications } from "react-notifications-component";
import {
  successCreationNotification,
  successUpdateNotification,
} from "../../components/notifications";

export const Criterias = () => {
  const WORKSPACE = "APPLICATIONS CRITERIA";
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const object = useSelector((state) => state.cdat.criteria);
  const [clicked, setclicked] = useState(false);
  const [criteria, setcriteria] = useState("");

  const token = useSelector((state) => state.auth.token);
  const userNames = useSelector((state) => state.auth.displayName);

  const onSubmit = (e) => {
    e.preventDefault();
    if (criteria === "") {
      setclicked(true);
    } else {
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            criteria,
            id: object?.uuid,
          };
          dispatch(actions.updateCriterias(token, obj));
          setcriteria("");
          setclicked(false);
          successUpdateNotification(WORKSPACE, "Updated");
        } else {
        }
      } else {
        if (window.confirm("Are you sure you want submit?")) {
          const obj = {
            criteria,
            creator: userNames,
          };
          dispatch(actions.createCriterias(obj, token));
          setcriteria("");
          setclicked(false);
          successCreationNotification(WORKSPACE);
        } else {
        }
      }
    }
  };

  const activating = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want change the status?")) {
      if (object?.status === constants.active) {
        dispatch(actions.desActivatingCriterias(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Inactive");
      } else {
        dispatch(actions.activatingCriterias(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Active");
      }
    } else {
    }
  };

  useEffect(() => {
    if (params?.objectId && object === null) {
      dispatch(actions.getOneCriterias(token, params?.objectId));
    }
    if (object !== null) {
      setcriteria(object?.criteria);
    }
  }, [object]);

  return (
    <>
      <AdminHeader />
      <ReactNotifications />
      <div className="container">
        <Beadcrumb />
        <div className="py-3"></div>
        <PageTitle pagetitle={WORKSPACE} />
        <div id="contact" className="input-data">
          <div className="" data-aos="fade-up">
            <div className="row d-flex php-publishingdate-form">
              <div className="col-md-9">
                <div className="row">
                  <div className="col-sm-10 col-md-12">
                    <div className="form-group py-1">
                      <InputText2
                        title={"Criteria (Kinya/English)"}
                        value={criteria}
                        name="criteria"
                        placeholder={t("Criteria")}
                        isClicked={clicked}
                        onChange={(e) => setcriteria(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="pt-3">
                  <div className="row gx-5"></div>
                  <SubmitButton
                    classes={"btn btn-primary btn-primary"}
                    action={object ? "Update" : "Create"}
                    onSubmit={onSubmit}
                  />
                  <span className="px-2"></span>
                  <ActivateButton
                    status={object?.status}
                    onSubmit={activating}
                  />
                </div>
              </div>
              <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                <div>
                  <div className="flex flex-column h-50">
                    <PageLink page={"/criterias-objects-list"} title={"List"} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const CriteriasList = () => {
  const WORKSPACE = "Applications Criterias";
  const loading = useSelector((state) => state.cdat.criteriasLoading);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const object = useSelector((state) => state.cdat.criteria);
  const objectList = useSelector((state) => state.cdat.criterias);

  useEffect(() => {
    dispatch(actions.getCriterias());
  }, []);

  const [show, setShow] = useState(false);
  const handleClose = (e) => {
    e.preventDefault();
    dispatch(actions.getCriterias());
    setShow(false);
  };

  const viewMore = (id) => {
    dispatch(actions.getOneCriterias(token, id));
    setShow(true);
  };

  return (
    <div className="">
      <AdminHeader />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <span>LOADING...</span>
        ) : (
          <>
            <section id="contact" className="input-data">
              <div className="container" data-aos="fade-up">
                <div className="row d-flex justify-content-end php-email-form">
                  <div className="col-md-9">
                    <div className="text-center">
                      <h5 className="bdf-bluefontcolor">{WORKSPACE} LIST</h5>
                    </div>
                    <table className="table table-striped">
                      <thead>
                        <tr className="bdf-bluefontcolor">
                          <th>#</th>
                          <th>Title</th>
                          <th>Status</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody className="">
                        {objectList?.map((data, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{data?.criteria}</td>
                            <td>{data?.status}</td>
                            <td className="row d-flex justify-content-center">
                              <EditButton
                                title="Edit"
                                page="/criterias"
                                id={data?.uuid}
                              />{" "}
                              <span className="px-2 w-auto"></span>
                              <ViewContentBtn
                                title="Values"
                                page="/criteria-values"
                                id={data?.uuid}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                    <div>
                      <div className="flex flex-column h-50">
                        <PageLink page="/criterias" title={"New Criteria"} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </main>
    </div>
  );
};

export const CriteriaValues = function (props) {
  const WORKSPACE = "Criteria Values";
  const history = useHistory();
  const params = useParams();
  const [counter, setcounter] = useState(0);
  const dispatch = useDispatch();
  const [clicked, setclicked] = useState(false);
  const [btndisabled, setbtndisabled] = useState(false);
  const object = useSelector((state) => state.cdat.criteria);
  const loading = useSelector((state) => state.cdat.criteriasLoading);
  const criteriaValues = useSelector((state) => state.cdat.criteriaValues);
  const componentLoading = useSelector(
    (state) => state.cdat.criteriaValuesLoading
  );
  useEffect(() => {
    if (params?.objectId && object === null) {
      dispatch(actions.getOneCriterias(token, params?.objectId));
    }
    if (object !== null) {
      dispatch(actions.getCriteriaValues(params?.objectId));
    }
  }, [object]);
  const token = useSelector((state) => state.auth.token);
  return (
    <div className="">
      <AdminHeader />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <span>Loading....</span>
        ) : (
          <section id="contact" className="input-data">
            <div className="container" data-aos="fade-up">
              <div className="row d-flex justify-content-end php-email-form">
                <div className="col-md-8">
                  <div className="text-center">
                    <h5 className="bdf-bluefontcolor">
                      {}
                      {object?.criteria} {WORKSPACE}
                    </h5>
                  </div>
                  <table className="table table-striped">
                    <thead>
                      <tr className="bdf-bluefontcolor">
                        <th>#</th>
                        <th>criteriaValue</th>
                        <th>Score</th>
                        <th>Status</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {criteriaValues?.map((data, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{data?.criteriaValue}</td>
                          <td>{data?.rating}</td>
                          <td>{data?.status}</td>
                          <td className="row d-flex justify-content-center">
                            <EditButton
                              title="Edit"
                              page="/criteria-values-new"
                              id={data?.uuid}
                              parentId={object?.uuid}
                            />
                            <span className="px-2"></span>
                            <ViewContentBtn
                              title="Values"
                              page="/criteria-values-subs"
                              id={data?.uuid}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                  <div>
                    <div className="flex flex-column h-50 text-center">
                      <div>
                        <PageLink
                          page={"/criteria-values-new"}
                          title={"New Value"}
                        />
                      </div>
                      <span></span>
                      <div className="pt-5">
                        <PageLink
                          page={"/criterias-objects-list"}
                          title={"Criteria"}
                        />
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
    </div>
  );
};

export const CriteriaValuesNew = function (props) {
  const WORKSPACE = "Criteria Value";
  const history = useHistory();
  const params = useParams();
  const [counter, setcounter] = useState(0);
  const dispatch = useDispatch();
  const [clicked, setclicked] = useState(false);
  const [btndisabled, setbtndisabled] = useState(false);
  const parentObject = useSelector((state) => state.cdat.criteria);
  const object = useSelector((state) => state.cdat.criteriaValue);
  const loading = useSelector((state) => state.cdat.criteriaValuesLoading);
  useEffect(() => {
    if (params?.parentId && parentObject === null) {
      dispatch(actions.getOneCriterias(token, params?.parentId));
    }
    if (params?.objectId && object === null) {
      dispatch(actions.getOneCriteriaValues(token, params?.objectId));
    }
    if (object !== null) {
      setcriteriaValue(object?.criteriaValue);
      setrating(object?.rating);
    }
  }, [object]);
  const [criteriaValue, setcriteriaValue] = useState("");
  const [rating, setrating] = useState("");
  const userNames = useSelector((state) => state.auth.displayName);
  const token = useSelector((state) => state.auth.token);
  const [objects, setObjects] = useState([]);

  const activating = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want change the status?")) {
      if (object?.status === constants.active) {
        dispatch(actions.desActivatingCriteriaValues(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Inactive");
      } else {
        dispatch(actions.activatingCriteriaValues(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Active");
      }
    } else {
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (criteriaValue === "" || rating === "") {
      setclicked(true);
    } else {
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            criteriaValue,
            rating,
            id: object.uuid,
          };
          dispatch(actions.updateCriteriaValues(token, obj));
          setcriteriaValue("");
          setrating("");
          setclicked(false);
          successUpdateNotification(WORKSPACE, "Updated");
        } else {
        }
      } else {
        if (window.confirm("Are you sure you want submit?")) {
          const object = {
            criteriaValue,
            rating,
            criteriaId: parentObject?.uuid,
            creator: userNames,
          };
          dispatch(actions.createCriteriaValues(object, token));
          setcriteriaValue("");
          setrating("");
          setclicked(false);
          setbtndisabled(false);
          successCreationNotification(WORKSPACE);
        } else {
        }
      }
    }
  };

  return (
    <div className="">
      <AdminHeader />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <span>Loading....</span>
        ) : (
          <section id="contact" className="input-data">
            <div className="container" data-aos="fade-up">
              <div className="row d-flex justify-content-end php-email-form">
                <div className="col-md-8">
                  <div className="">
                    <div className="">
                      {object !== null ? (
                        <>
                          <h5 className="bdf-bluefontcolor">
                            EDITING {WORKSPACE} IN {parentObject?.criteria}
                          </h5>
                        </>
                      ) : (
                        <h5 className="bdf-bluefontcolor">
                          CREATE {WORKSPACE} IN {parentObject?.criteria}
                        </h5>
                      )}
                    </div>
                    <div className="form-group py-3">
                      <InputText2
                        title={"Title"}
                        value={criteriaValue}
                        name="criteriaValue"
                        placeholder="Title"
                        isClicked={clicked}
                        onChange={(e) => setcriteriaValue(e.target.value)}
                      />
                    </div>
                    <div className="form-group py-3">
                      <InputText2
                        title={"Score/Rating"}
                        value={rating}
                        name="rating"
                        placeholder="Score/Rating"
                        isClicked={clicked}
                        onChange={(e) => setrating(e.target.value)}
                      />
                    </div>
                    <div className="row gx-5"></div>
                    <SubmitButton
                      disabled={btndisabled}
                      action={object === null ? "Create" : "Update"}
                      onSubmit={onSubmit}
                    />
                    <span className="px-5"></span>
                    <ActivateButton
                      status={object?.status}
                      onSubmit={activating}
                    />
                  </div>
                </div>
                <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                  <div>
                    <div className="flex flex-column h-50 text-center">
                      <div>
                        <PageLink
                          page={"/criteria-values/" + parentObject?.uuid}
                          title={"Criteria Values"}
                        />
                      </div>
                      <div className="pt-5">
                        <PageLink
                          page={"/criterias-objects-list"}
                          title={"Criterias"}
                        />
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
    </div>
  );
};

export const CriteriaValuesSubs = function (props) {
  const WORKSPACE = "Criteria Values Subs";
  const history = useHistory();
  const params = useParams();
  const [counter, setcounter] = useState(0);
  const dispatch = useDispatch();
  const [clicked, setclicked] = useState(false);
  const [btndisabled, setbtndisabled] = useState(false);
  const object = useSelector((state) => state.cdat.criteriaValue);
  const sub = useSelector((state) => state.cdat.criteriaValueSubs);
  const loading = useSelector((state) => state.cdat.criteriasLoading);
  const criteriaValueSubs = useSelector(
    (state) => state.cdat.criteriaValueSubs
  );
  const componentLoading = useSelector(
    (state) => state.cdat.criteriaValueSubsLoading
  );
  useEffect(() => {
    if (params?.objectId && object === null) {
      dispatch(actions.getOneCriteriaValues(token, params?.objectId));
    }
    if (object !== null) {
      dispatch(actions.getCriteriaValuesSubs(params?.objectId));
    }
  }, [object]);
  const token = useSelector((state) => state.auth.token);
  return (
    <div className="">
      <AdminHeader />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <span>Loading....</span>
        ) : (
          <section id="contact" className="input-data">
            <div className="container" data-aos="fade-up">
              <div className="row d-flex justify-content-end php-email-form">
                <div className="col-md-8">
                  <div className="text-center">
                    <h5 className="bdf-bluefontcolor">
                      {}
                      {object?.criteriaValue} {WORKSPACE}
                    </h5>
                  </div>
                  <table className="table table-striped">
                    <thead>
                      <tr className="bdf-bluefontcolor">
                        <th>#</th>
                        <th>criteriaValue</th>
                        <th>Score</th>
                        <th>Status</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {criteriaValueSubs?.map((data, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{data?.criteriaValue}</td>
                          <td>{data?.rating}</td>
                          <td>{data?.status}</td>
                          <td className="row d-flex justify-content-center">
                            <EditButton
                              title="Edit"
                              page="/criteria-values-subs-new"
                              id={data?.uuid}
                              parentId={object?.uuid}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                  <div>
                    <div className="flex flex-column h-50 text-center">
                      <div>
                        <PageLink
                          page={"/criteria-values-subs-new"}
                          title={"New Value Sub"}
                        />
                      </div>
                      <span></span>
                      <div className="pt-5">
                        <PageLink
                          page={"/criterias-objects-list"}
                          title={"Criteria"}
                        />
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
    </div>
  );
};

export const CriteriaValuesSubsNew = function (props) {
  const WORKSPACE = "Criteria Value Subs";
  const history = useHistory();
  const params = useParams();
  const [counter, setcounter] = useState(0);
  const [minimumStorageCapacity, setminimumStorageCapacity] = useState(0);
  const dispatch = useDispatch();
  const [clicked, setclicked] = useState(false);
  const [btndisabled, setbtndisabled] = useState(false);
  const parentObject = useSelector((state) => state.cdat.criteriaValue);
  const object = useSelector((state) => state.cdat.criteriaValueSub);
  const loading = useSelector((state) => state.cdat.criteriaValueSubsLoading);
  useEffect(() => {
    if (params?.parentId && parentObject === null) {
      dispatch(actions.getOneCriteriaValues(token, params?.parentId));
    }
    if (params?.objectId && object === null) {
      dispatch(actions.getOneCriteriaValuesSubs(token, params?.objectId));
    }
    if (object !== null) {
      setcriteriaValue(object?.criteriaValue);
      setrating(object?.rating);
    }
  }, [object]);
  const [criteriaValue, setcriteriaValue] = useState("");
  const [rating, setrating] = useState("");
  const userNames = useSelector((state) => state.auth.displayName);
  const token = useSelector((state) => state.auth.token);
  const [objects, setObjects] = useState([]);

  const [hasTextField, sethasTextField] = useState(0);
  const [isGrain, setisGrain] = useState(0);
  const [isStorage, setisStorage] = useState(0);
  const [isDryer, setisDryer] = useState(0);
  const [hasStorage, sethasStorage] = useState(0);
  const [storageCapacity, setstorageCapacity] = useState(0);
  const [dryerCapacity, setdryerCapacity] = useState(0);

  const activating = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want change the status?")) {
      if (object?.status === constants.active) {
        dispatch(actions.desActivatingCriteriaValuesSubs(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Inactive");
      } else {
        dispatch(actions.activatingCriteriaValuesSubs(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Active");
      }
    } else {
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (criteriaValue === "" || rating === "") {
      setclicked(true);
    } else {
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            criteriaValue,
            rating,
            id: object.uuid,
          };
          dispatch(actions.updateCriteriaValuesSubs(token, obj));
          setcriteriaValue("");
          sethasTextField("");
          setrating("");
          setisGrain(0);
          setisStorage(0);
          setisDryer(0);
          sethasStorage(0);
          setstorageCapacity(0);
          setdryerCapacity(0);
          setclicked(false);
          successUpdateNotification(WORKSPACE, "Updated");
        } else {
        }
      } else {
        if (window.confirm("Are you sure you want submit?")) {
          const object = {
            criteriaValue,
            rating,
            hasTextField: hasTextField === constants.isTrue ? 1 : 0,
            isGrain: isGrain === constants.isTrue ? 1 : 0,
            isStorage: isStorage === constants.isTrue ? 1 : 0,
            isDryer: isDryer === constants.isTrue ? 1 : 0,
            hasStorage: hasStorage === constants.isTrue ? 1 : 0,
            storageCapacity,
            dryerCapacity,
            parentId: parentObject?.uuid,
            creator: userNames,
          };
          //console.log(object);
          dispatch(actions.createCriteriaValuesSubs(object, token));
          setcriteriaValue("");
          setrating("");
          setclicked(false);
          setbtndisabled(false);
          sethasTextField("");
          setisGrain(0);
          setisStorage(0);
          setisDryer(0);
          sethasStorage(0);
          setstorageCapacity(0);
          setdryerCapacity(0);
          successCreationNotification(WORKSPACE);
        } else {
        }
      }
    }
  };

  return (
    <div className="">
      <AdminHeader />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <span>Loading....</span>
        ) : (
          <section id="contact" className="input-data">
            <div className="container" data-aos="fade-up">
              <div className="row d-flex justify-content-end php-email-form">
                <div className="col-md-8">
                  <div className="">
                    <div className="">
                      {object !== null ? (
                        <>
                          <h5 className="bdf-bluefontcolor">
                            EDITING {WORKSPACE} IN {parentObject?.criteriaValue}
                          </h5>
                        </>
                      ) : (
                        <h5 className="bdf-bluefontcolor">
                          CREATE {WORKSPACE} IN {parentObject?.criteriaValue}
                        </h5>
                      )}
                    </div>
                    <div className="form-group py-3">
                      <InputText2
                        title={"Title"}
                        value={criteriaValue}
                        name="criteriaValue"
                        placeholder="Title"
                        isClicked={clicked}
                        onChange={(e) => setcriteriaValue(e.target.value)}
                      />
                    </div>
                    <div className="form-group py-3">
                      <InputText2
                        title={"Score/Rating"}
                        value={rating}
                        name="rating"
                        placeholder="Score/Rating"
                        isClicked={clicked}
                        onChange={(e) => setrating(e.target.value)}
                      />
                    </div>
                    <div className="row col-md-12 pt-3">
                      <div className="col-md-5">
                        <SelectRadioButton
                          title="Does Storage Exist?"
                          option1={constants.isTrue}
                          option2={constants.isFalse}
                          name="hasStorage"
                          value={hasStorage}
                          isClicked={clicked}
                          onChange={(e) => sethasStorage(e.target.value)}
                        />
                      </div>
                      <div className="col-md-5">
                        <SelectRadioButton
                          title="Is Storage?"
                          option1={constants.isTrue}
                          option2={constants.isFalse}
                          name="isStorage"
                          value={isStorage}
                          isClicked={clicked}
                          onChange={(e) => setisStorage(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="row col-md-12 pt-3">
                      <div className="col-md-5">
                        <SelectRadioButton
                          title="Is Parent Criteria Grain?"
                          option1={constants.isTrue}
                          option2={constants.isFalse}
                          name="isGrain"
                          value={isGrain}
                          isClicked={clicked}
                          onChange={(e) => setisGrain(e.target.value)}
                        />
                      </div>
                      <div className="col-md-5">
                        <SelectRadioButton
                          title="Is Dryer?"
                          option1={constants.isTrue}
                          option2={constants.isFalse}
                          name="isDryer"
                          value={isDryer}
                          isClicked={clicked}
                          onChange={(e) => setisDryer(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="row col-md-12">
                      <div className="form-group py-3 col-md-5">
                        <InputText2
                          title={"Minimum Storage Capacity (tons)"}
                          value={storageCapacity}
                          name="storageCapacity"
                          placeholder="Storage Capacity"
                          isClicked={clicked}
                          onChange={(e) => setstorageCapacity(e.target.value)}
                        />
                      </div>
                      <div className="form-group py-3 col-md-5">
                        <InputText2
                          title={"Machine Capacity per Hour"}
                          value={dryerCapacity}
                          name="dryerCapacity"
                          placeholder="Storage Capacity"
                          isClicked={clicked}
                          onChange={(e) => setdryerCapacity(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="row gx-5"></div>
                    <SubmitButton
                      disabled={btndisabled}
                      action={object === null ? "Create" : "Update"}
                      onSubmit={onSubmit}
                    />
                    <span className="px-5"></span>
                    <ActivateButton
                      status={object?.status}
                      onSubmit={activating}
                    />
                  </div>
                </div>
                <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                  <div>
                    <div className="flex flex-column h-50 text-center">
                      <div>
                        <PageLink
                          page={"/criteria-values-subs/" + parentObject?.uuid}
                          title={"Criteria Values Subs"}
                        />
                      </div>
                      <div className="pt-5">
                        <PageLink
                          page={"/criterias-objects-list"}
                          title={"Criterias"}
                        />
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
    </div>
  );
};
