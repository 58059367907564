import { useEffect, useState } from "react";
import Header from "../components/header";
import {
  ActivateButton,
  InputDate2,
  InputEmail,
  InputNationalId,
  InputPassport,
  InputTelephone,
  InputText2,
  InputText22nd,
  InputTIN,
  SelectInput,
  SubmitButton,
} from "../components/inputs";
import { Beadcrumb, CountDown, PageTitle } from "../components/utils";
import { useTranslation } from "react-i18next";
import constants from "../components/constants";
import { Provinces, Districts, Sectors } from "rwanda";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  findOneOldApplicants,
  getApplicantByEmail,
  getApplicantByNationalId,
  getApplicantByTelephone,
  getApplicantByTin,
  getApplicationsSuccesInit,
  getOldApplicantsStart,
  getOneApplicationsByEmailStart,
  getOneApplicationsByNIDStart,
  getOneApplicationsByTelStart,
  getOneApplicationsByTinStart,
  getOneApplicationsByTinFail,
} from "../store/actions/applications";
import moment from "moment";
import Input from "../components/theinputs";
// import { useDispatch, useSelector } from "react-redux";

function PersonalDetails() {
  const WORKSPACE = "UMWIRONDORO/APPLICANT DETAILS";
  const { t } = useTranslation();
  const history = useHistory();
  const [clicked, setclicked] = useState(false);
  const [uniclicked, setuniclicked] = useState(false);
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [telephone, settelephone] = useState("");
  const [email, setemail] = useState("");
  const [nationalId, setnationalId] = useState("");
  const [gender, setGender] = useState("");
  const [province, setProvince] = useState("");
  const [district, setDistrict] = useState("");
  const [sector, setSector] = useState("");
  const [districts, setDistricts] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [genderError, setGenderError] = useState(false);
  const [dob, setdob] = useState(new Date());
  const [ddob, setddob] = useState(new Date());
  const [idtype, setidtype] = useState("");
  const [companyname, setcompanyname] = useState("");
  const [tin, settin] = useState("");

  const application = useSelector((state) => state.applications.application);
  const applicationByNid = useSelector(
    (state) => state.applications.applicationByNid
  );
  const applicationByTel = useSelector(
    (state) => state.applications.applicationByTel
  );
  const applicationByEmail = useSelector(
    (state) => state.applications.applicationByEmail
  );
  const applicationByTin = useSelector(
    (state) => state.applications.applicationByTin
  );

  const activePeriod = useSelector((state) => state.periods?.activePeriod);

  const oldApplicant = useSelector((state) => state.applications.oldApplicant);
  // const application = useSelector((state) => state.applications.application);
  const [applicanttype, setapplicanttpe] = useState(
    application?.applicanttype ?? ""
  );
  const dispatch = useDispatch();
  const setTheGender = (value) => {
    setGender(value);
    setclicked(false);
  };

  const setTheApplicantType = (value) => {
    setapplicanttpe(value);
    setclicked(false);
  };

  const setTheIDType = (value) => {
    setidtype(value);
  };

  const [IdUniqueError, setIduniqueError] = useState("false");
  const [TelUniqueError, setTeluniqueError] = useState("false");
  const [EmailUniqueError, setEmailuniqueError] = useState("false");
  const [TINUniqueError, setTINUniqueError] = useState("false");
  const [oldApplicantError, setOldApplicantError] = useState("false");

  const deadline = useSelector(
    (state) => state.periods?.activePeriod?.closingdate
  );

  const arrayToOptionsHandler = (arr = []) => {
    return arr.map((element) => {
      const item = {};
      item.value = element;
      if (element === "East") {
        item.name = element + " | Uburasirazuba";
      } else if (element === "West") {
        item.name = element + " | Uburengerazuba";
      } else if (element === "North") {
        item.name = element + " | Amajyaruguru";
      } else if (element === "South") {
        item.name = element + " | Amajyepfo";
      } else if (element === "Kigali") {
        item.name = element + " City | Umujyi wa Kigali";
      } else {
        item.name = element;
      }
      return item;
    });
  };

  const setTheProvince = (value) => {
    setProvince(value);
    setDistricts(arrayToOptionsHandler(Districts(value)));
    setSectors([]);
    setclicked(false);
  };
  const setTheDistrict = (value) => {
    setDistrict(value);
    setSectors(arrayToOptionsHandler(Sectors(province, value)));
    setclicked(false);
  };

  useEffect(() => {
    if (activePeriod === null) {
      history.push("/");
    }
    if (new Date(deadline).getTime() - new Date().getTime() < 0) {
      history.push("/");
    }
    if (application) {
      setfirstname(application?.firstname);
      setlastname(application?.lastname);
      setGender(application?.gender);
      setnationalId(application?.nationalId);
      settelephone(application?.telephone);
      setemail(application?.email);
      settin(application?.tin);
      setcompanyname(application?.companyname);
    }
    if (applicationByNid !== null) {
      setIduniqueError("true");
    } else {
      setIduniqueError("false");
    }
    if (applicationByTel !== null) {
      setTeluniqueError("true");
    } else {
      setTeluniqueError("false");
    }
    if (applicationByEmail !== null) {
      setEmailuniqueError("true");
    } else {
      setTeluniqueError("false");
    }
    if (applicationByTin !== null) {
      setTINUniqueError("true");
    } else {
      setTINUniqueError("false");
    }
    if (oldApplicant !== null) {
      setOldApplicantError("true");
    } else {
      setOldApplicantError("false");
    }
    if (
      nationalId !== undefined &&
      nationalId?.trim()?.length === 16 &&
      applicationByNid === null
    ) {
      dispatch(getApplicantByNationalId(nationalId));
    }
    if (
      nationalId !== undefined &&
      nationalId?.trim()?.length === 16 &&
      oldApplicant === null
    ) {
      dispatch(findOneOldApplicants(nationalId));
    }
    if (
      email !== undefined &&
      constants.validateEmail(email) === true &&
      applicationByEmail === null
    ) {
      dispatch(getApplicantByEmail(email));
    }
    if (
      telephone !== undefined &&
      telephone?.trim().length === 10 &&
      applicationByTel === null
    ) {
      dispatch(getApplicantByTelephone(telephone));
    }
    if (
      tin !== undefined &&
      tin?.trim().length > 6 &&
      applicationByTin === null
    ) {
      let thetin = tin.replaceAll("/", "-");
      dispatch(getApplicantByTin(thetin));
    }
    if (tin !== undefined && tin?.trim().length > 6 && oldApplicant === null) {
      let thetin = tin.replaceAll("/", "-");
      dispatch(findOneOldApplicants(thetin));
    }
  }, [
    application,
    deadline,
    nationalId,
    email,
    telephone,
    tin,
    applicationByNid,
    applicationByEmail,
    applicationByTel,
    applicationByTin,
    oldApplicant,
  ]);

  const onSubmit = (e) => {
    e.preventDefault();
    var date = new Date();
    var thedate = new Date(dob);
    const newDate = date.getTime() - thedate.getTime();
    const years = Math.floor(newDate / (1000 * 60 * 60 * 24) / 365);
    dispatch(getApplicantByTelephone(telephone));
    dispatch(getApplicantByEmail(email));
    dispatch(getApplicantByNationalId(nationalId));
    dispatch(getApplicantByTin(tin));
    if (new Date(deadline).getTime() - new Date().getTime() < 0) {
      alert("Applications not allowed at the moment");
    } else {
      if (gender !== "male" || gender !== "female") {
        setGenderError(true);
      } else {
        setGenderError(false);
      }
      if (applicanttype === "individual") {
        if (
          firstname.trim() === "" ||
          lastname.trim() === "" ||
          nationalId.trim() === "" ||
          nationalId.trim().length !== 16 ||
          telephone.trim() === "" ||
          telephone.trim().length !== 10 ||
          province.trim() === "" ||
          district.trim() === "" ||
          sector.trim() === "" ||
          email.trim() === "" ||
          gender.trim() === "" ||
          applicationByNid !== null ||
          applicationByTel !== null ||
          applicationByEmail !== null ||
          applicationByTin !== null ||
          (oldApplicant !== null &&
            activePeriod?.allowoldapplicants === "false")
        ) {
          setclicked(true);
        } else {
            if (
              window.confirm("Urashaka kohereza? Are you sure you want submit?")
            ) {
              let tel = telephone;
              if (telephone.startsWith("+25")) {
                tel = telephone.replace("+25", "");
              }
              const object = {
                firstname,
                lastname,
                gender,
                nationalId,
                telephone: tel,
                email,
                province,
                district,
                sector,
                dob: moment(dob).format("YYYY-MM-DD"),
                dd: ddob,
                idtype,
                applicanttype,
                companyname,
                tin,
              };
              dispatch(getApplicationsSuccesInit(object));
              setfirstname("");
              setlastname("");
              setGender("");
              settelephone("");
              setemail("");
              setnationalId("");
              setProvince("");
              setDistrict("");
              setSector("");
              setclicked(false);
              setGenderError(false);
              history.push("/application");
            } else {
            }
        }
      } else {
        if (
          firstname.trim() === "" ||
          lastname.trim() === "" ||
          tin.trim() === "" ||
          email.trim() === "" ||
          telephone.trim() === "" ||
          province.trim() === "" ||
          district.trim() === "" ||
          sector.trim() === "" ||
          companyname.trim() === "" ||
          tin.trim() === "" ||
          applicationByNid !== null ||
          applicationByTel !== null ||
          applicationByEmail !== null ||
          applicationByTin !== null ||
          (oldApplicant !== null &&
            activePeriod?.allowoldapplicants === "false")
        ) {
          setclicked(true);
        } else {
          if (
            window.confirm("Urashaka kohereza? Are you sure you want submit?")
          ) {
            let tel = telephone;
            if (telephone.startsWith("+25")) {
              tel = telephone.replace("+25", "");
            }
            const object = {
              firstname,
              lastname,
              tin,
              telephone: tel,
              email,
              province,
              district,
              sector,
              companyname,
              idtype,
              applicanttype,
              companyname,
              tin,
            };
            dispatch(getApplicationsSuccesInit(object));
            setfirstname("");
            setlastname("");
            setGender("");
            settelephone("");
            setemail("");
            setnationalId("");
            setProvince("");
            setDistrict("");
            setSector("");
            setclicked(false);
            setGenderError(false);
            history.push("/application");
          } else {
          }
        }
      }
    }
  };

  return (
    <>
      <Header />
      <div className="container">
        <Beadcrumb />
        <div className="pt-3">
          <CountDown />
        </div>
        <PageTitle pagetitle={WORKSPACE} />
        <div
          className={
            activePeriod?.allowoldapplicants === "false" &&
            oldApplicantError === "true"
              ? "alert alert-danger text-center"
              : "d-none"
          }
          role="alert"
        >
          Ntabwo mwemere inkunga kubera mwabonye {oldApplicant?.receivedgrant} /
          You are not allowed grant because you received{" "}
          {oldApplicant?.receivedgrant}
        </div>
        <div id="contact" className="input-data">
          <div className="" data-aos="fade-up">
            <div className="row d-flex php-email-form">
              <div className="col-md-12">
                <div className="row d-flex">
                  <div className="col-sm-10 col-md-6">
                    <div className="form-group py-1">
                      <SelectInput
                        title="Applicant Type/Usaba ni inde? "
                        startingValue={"HITAMO/SELECT"}
                        options={constants.applicanttypes}
                        value={applicanttype}
                        changed={setTheApplicantType}
                        isClicked={clicked}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={
                    applicanttype == constants.individual ? "d-show" : "d-none"
                  }
                >
                  <div className="row ">
                    <div className="col-sm-10 col-md-6">
                      <div className="form-group py-1">
                        <InputText2
                          title={"Surname/Izina ry'umuryango"}
                          value={firstname}
                          name="firstname"
                          placeholder={t("Surname/Izina ry'umuryango")}
                          isClicked={clicked}
                          onChange={(e) => {
                            setfirstname(e.target.value);
                            setclicked(false);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-sm-10 col-md-6">
                      <div className="form-group py-1">
                        <InputText2
                          title={"Given names/Andi mazina"}
                          value={lastname}
                          name="lastname"
                          placeholder={t("Other names/Andi mazina")}
                          isClicked={clicked}
                          onChange={(e) => {
                            setlastname(e.target.value);
                            setclicked(false);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row d-flex">
                    <div className="col-sm-10 col-md-6">
                      <div className="form-group py-1">
                        <SelectInput
                          title="Igitsina/Gender"
                          startingValue={"HITAMO/SELECT"}
                          options={constants.genders}
                          value={gender}
                          changed={setTheGender}
                          isClicked={clicked}
                        />
                      </div>
                    </div>
                    <div className={"d-show col-sm-10 col-md-6"}>
                      <div className="form-group py-1">
                        <InputNationalId
                          unique={IdUniqueError}
                          title={"Indangamuntu/National ID"}
                          value={nationalId}
                          name="nationalId"
                          gender={gender}
                          placeholder={"National ID/Indangamuntu"}
                          isClicked={clicked}
                          onChange={(e) => {
                            setnationalId(e.target.value);
                            setIduniqueError("false");
                            setclicked(false);
                            setOldApplicantError("false");
                            dispatch(getOldApplicantsStart());
                            dispatch(getOneApplicationsByNIDStart());
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row d-flex ">
                    <div className="col-sm-10 col-md-6">
                      <div className="form-group py-1">
                        <InputEmail
                          unique={EmailUniqueError}
                          title={"E-mail/Imeyiri"}
                          value={email}
                          name="email"
                          placeholder={"E-mail/Imeyiri"}
                          isClicked={clicked}
                          onChange={(e) => {
                            setemail(e.target.value);
                            dispatch(getOneApplicationsByEmailStart());
                            setEmailuniqueError("false");
                            setclicked(false);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-sm-10 col-md-6">
                      <div className="form-group py-1">
                        <InputTelephone
                          unique={TelUniqueError}
                          title={"Telephone Number/Nimero ya telefoni"}
                          value={telephone}
                          name="telephone"
                          placeholder={"07xxxxxxxx"}
                          isClicked={clicked}
                          onChange={(e) => {
                            settelephone(e.target.value);
                            setclicked(false);
                            setTeluniqueError("false");
                            dispatch(getOneApplicationsByTelStart());
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row d-flex ">
                    <label className="pt-1 bdf-bluefontcolor">
                      LOCATION/AHO UBARIZWA
                    </label>
                    <div className="row">
                      <div className="col-md-3">
                        <SelectInput
                          title="Province/Intara"
                          startingValue={"HITAMO/SELECT"}
                          options={arrayToOptionsHandler(Provinces())}
                          value={province}
                          changed={setTheProvince}
                          isClicked={clicked}
                        />
                      </div>
                      <div className="col-md-3">
                        <SelectInput
                          title="District/Akarere"
                          startingValue={"HITAMO/SELECT"}
                          options={districts}
                          value={district}
                          changed={setTheDistrict}
                          isClicked={clicked}
                        />
                      </div>
                      <div className="col-md-3">
                        <SelectInput
                          title="Sector/Umurenge"
                          startingValue={"HITAMO/SELECT"}
                          options={sectors}
                          value={sector}
                          changed={setSector}
                          isClicked={clicked}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pt-3">
                    <div className="row gx-5"></div>
                    <SubmitButton
                      action={"Continue/Komeza"}
                      disabled={clicked}
                      onSubmit={onSubmit}
                    />
                    <span className="px-2"></span>
                  </div>
                </div>
                <div
                  className={
                    applicanttype == constants.cooperative ||
                    applicanttype == constants.company
                      ? "d-show"
                      : "d-none"
                  }
                >
                  <div className="row">
                    <div className="col-sm-10 col-md-6">
                      <div className="form-group py-1">
                        <InputText2
                          title={"Company/Cooperative Name/Izina"}
                          value={companyname}
                          name="companyname"
                          placeholder={t("Name/Izina")}
                          isClicked={clicked}
                          onChange={(e) => {
                            setcompanyname(e.target.value);
                            setclicked(false);
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className={
                        applicanttype === constants.company
                          ? "col-sm-10 col-md-6"
                          : "d-none"
                      }
                    >
                      <div className="form-group py-1">
                        <InputTIN
                          type={"tin"}
                          unique={TINUniqueError}
                          title={"TIN"}
                          value={tin}
                          name="tin"
                          placeholder={"TIN"}
                          isClicked={clicked}
                          onChange={(e) => {
                            settin(e.target.value);
                            setclicked(false);
                            setTINUniqueError("false");
                            setOldApplicantError("false");
                            dispatch(getOldApplicantsStart());
                            dispatch(getOneApplicationsByTinStart());
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className={
                        applicanttype === constants.cooperative
                          ? "col-sm-10 col-md-6"
                          : "d-none"
                      }
                    >
                      <div className="form-group py-1">
                        <InputTIN
                          type={"rca"}
                          unique={TINUniqueError}
                          title={"RCA Number"}
                          value={tin}
                          name="tin"
                          placeholder={"RCA Number"}
                          isClicked={clicked}
                          onChange={(e) => {
                            settin(e.target.value);
                            setclicked(false);
                            setTINUniqueError("false");
                            setOldApplicantError("false");
                            dispatch(getOldApplicantsStart());
                            dispatch(getOneApplicationsByTinStart());
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-10 col-md-6">
                      <div className="form-group py-1">
                        <InputText2
                          title={
                            "Representetative Name/Izina ry'uhagariraye company"
                          }
                          value={firstname}
                          name="firstname"
                          placeholder={t("Name/Izina")}
                          isClicked={clicked}
                          onChange={(e) => {
                            setfirstname(e.target.value);
                            setclicked(false);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-sm-10 col-md-6">
                      <div className="form-group py-1">
                        <InputText2
                          title={
                            "Other names of Representetative/Andi mazina y'uhagarariye company"
                          }
                          value={lastname}
                          name="lastname"
                          placeholder={t("Other names/Andi mazina")}
                          isClicked={clicked}
                          onChange={(e) => {
                            setlastname(e.target.value);
                            setclicked(false);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row d-flex">
                    <div className="col-sm-10 col-md-6">
                      <div className="form-group py-1">
                        <InputTelephone
                          title={"Telephone Number/Nimero ya telefoni"}
                          unique={TelUniqueError}
                          value={telephone}
                          name="telephone"
                          placeholder={"07xxxxxxxx"}
                          isClicked={clicked}
                          onChange={(e) => {
                            settelephone(e.target.value);
                            setclicked(false);
                            setTeluniqueError("false");
                            dispatch(getOneApplicationsByTelStart());
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-sm-10 col-md-6">
                      <div className="form-group py-1">
                        <InputEmail
                          unique={EmailUniqueError}
                          title={"E-mail/Imeyiri "}
                          value={email}
                          name="email"
                          placeholder={"E-mail/Imeyiri"}
                          isClicked={clicked}
                          onChange={(e) => {
                            setemail(e.target.value);
                            setclicked(false);
                            setEmailuniqueError("false");
                            dispatch(getOneApplicationsByEmailStart());
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row d-flex">
                    <label className="pt-3 bdf-bluefontcolor">
                      LOCATION / AHO IBARIZWA
                    </label>
                    <div className="row">
                      <div className="col-md-3">
                        <SelectInput
                          title="Province/Intara"
                          startingValue={"HITAMO/SELECT"}
                          options={arrayToOptionsHandler(Provinces())}
                          value={province}
                          changed={setTheProvince}
                          isClicked={clicked}
                        />
                      </div>
                      <div className="col-md-3">
                        <SelectInput
                          title="District/Akarere"
                          startingValue={"HITAMO/SELECT"}
                          options={districts}
                          value={district}
                          changed={setTheDistrict}
                          isClicked={clicked}
                        />
                      </div>
                      <div className="col-md-3">
                        <SelectInput
                          title="Sector/Umurenge"
                          startingValue={"HITAMO/SELECT"}
                          options={sectors}
                          value={sector}
                          changed={setSector}
                          isClicked={clicked}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pt-3">
                    <div className="row gx-5"></div>
                    <SubmitButton
                      disabled={clicked}
                      action={"Continue/Komeza"}
                      onSubmit={onSubmit}
                    />
                    <span className="px-2"></span>
                  </div>
                </div>
              </div>
              <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PersonalDetails;
