import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import constants from "../components/constants";
import Header from "../components/header";
import {
  ActivateButton,
  EditButton,
  InputText2,
  InputTextOC,
  SelectInput,
  SelectInputCriteria,
  SelectInputCriteria2nd,
  SubmitButton,
  UplodadFile,
} from "../components/inputs";
import MiSpinner, {
  ApplicantsDetails,
  Beadcrumb,
  CountDown,
  formatDecimals,
  ObjectDetails,
  PageTitle,
} from "../components/utils";
import { getActiveCriterias, getCriteriaValuesSubs } from "../store/actions";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import {
  applicationInitialization,
  createApplications,
  getApplicationCriteriasSuccesInit,
  getApplicationsSuccesInit,
} from "../store/actions/applications";
import {
  errorNotificationDataMissing,
  successApplyingNotification,
  successCreationNotification,
} from "../components/notifications";
import { ReactNotifications } from "react-notifications-component";
import { Provinces, Districts, Sectors } from "rwanda";

function CriteriasPageV2() {
  const WORKSPACE =
    "SELECT WHAT APPLIES TO YOUR PROJECT/HITAMO IBISOBANURA NEZA UMUSHINGA WAWE";
  const [clicked, setclicked] = useState();
  const [valuess, setvaluess] = useState([]);
  const [childvalues, setchildvalues] = useState([]);
  const [criterias, setcriterias] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const deadline = useSelector(
    (state) => state.periods?.activePeriod?.closingdate
  );

  const objectsCriteria = useSelector((state) => state.cdat.criterias);
  const objectsCriteriaLoading = useSelector(
    (state) => state.cdat.criteriasLoading
  );
  const theApplication = useSelector((state) => state.applications.application);
  const applicationLoading = useSelector(
    (state) => state.applications.applicationsLoading
  );
  const oldApplicant = useSelector((state) => state.applications.oldApplicant);
  const subcriteria = useSelector((state) => state.cdat.criteriaValueSubs);
  const [projectname, setprojectname] = useState("");
  const [owncontribution, setowncontribution] = useState("");
  const [totalinvestment, settotalinvestment] = useState("");
  const [projprovince, setprojProvince] = useState("");
  const [projdistrict, setprojDistrict] = useState("");
  const [projsector, setprojSector] = useState("");
  const [districts, setDistricts] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [response, setresponse] = useState({});
  const [
    owncontributionasOnBankCertificate,
    setowncontributionasOnBankCertificate,
  ] = useState("");

  const [image, setimage] = useState(null);

  const [isChanging, setisChanging] = useState(false);

  const [showBankUpload, setshowBankUpload] = useState(0);
  const [submitted, setsubmitted] = useState(false);

  const [zeval, setzeval] = useState();
  const [isOldAppl, setisOldAppl] = useState(0);
  const [receivedGrant, setreceivedGrant] = useState("");

  //const isOldAppl = 1;

  const applicationError = useSelector(
    (state) => state.applications.applicationsFail
  );

  const [thecriteriaTitle, setthecriteriaTitle] = useState("Select /Hitamo ");

  const [idrdbrcacertificate, setidrdbrcacertificate] = useState(null);
  const [businessplan, setbusinessplan] = useState(null);
  const [bankcertficate, setbankcertficate] = useState(null);
  const [landcertificate, setlandcertificate] = useState(null);
  const [idrdbrcacertificatetype, setidrdbrcacertificatetype] = useState(null);
  const [businessplantype, setbusinessplantype] = useState(null);
  const [bankcertficatetype, setbankcertficatetype] = useState(null);
  const [landcertificatetype, setlandcertificatetype] = useState(null);
  const [storeboxerror, setstoreboxerror] = useState(0);
  const [dryerboxerror, setdryerboxerror] = useState(0);

  const [theisStorage, settheisStorage] = useState();
  const [theisDryer, settheisDryer] = useState();
  const [thehasStorage, setthehasStorage] = useState();
  const [thehasDryer, setthehasDryer] = useState();
  const [thestorageCapacity, setthestorageCapacity] = useState();
  const [thedryerCapacity, setthedryerCapacity] = useState();
  const [theexistingStorageCapacity, settheexistingStorageCapacity] =
    useState();
  const [theexistingDryerCapacity, settheexistingDryerCapacity] = useState();
  const [theproject, settheproject] = useState("");
  const [theselectedactivity, settheselectedactivity] = useState("");

  const [filename, setfilename] = useState(
    theApplication?.nationalId ?? theApplication?.tin
  );

  const arrayToOptionsHandler = (arr = []) => {
    return arr.map((element) => {
      const item = {};
      item.value = element;
      if (element === "East") {
        item.name = element + " | Uburasirazuba";
      } else if (element === "West") {
        item.name = element + " | Uburengerazuba";
      } else if (element === "North") {
        item.name = element + " | Amajyaruguru";
      } else if (element === "South") {
        item.name = element + " | Amajyepfo";
      } else if (element === "Kigali") {
        item.name = element + " City | Umujyi wa Kigali";
      } else {
        item.name = element;
      }

      return item;
    });
  };

  const setTheProvince = (value) => {
    setprojProvince(value);
    setDistricts(arrayToOptionsHandler(Districts(value)));
    setSectors([]);
    setclicked(false);
  };
  const setTheDistrict = (value) => {
    setprojDistrict(value);
    setSectors(arrayToOptionsHandler(Sectors(projprovince, value)));
    setclicked(false);
  };

  const refs = useRef([]);
  refs.current = [];

  const application = {
    ...theApplication,
    owncontribution: owncontribution.replace(/\,/g, ""),
    totalinvestment: totalinvestment.replace(/\,/g, ""),
    projectname,
    projdistrict,
    projprovince,
    projsector,
  };

  const [show, setShow] = useState(false);
  const [refresh, setrefresh] = useState(false);
  const [filesError, setfilesError] = useState(0);
  const [dbError, setdbError] = useState(null);
  const [submittedApplication, setsubmittedApplication] = useState({});

  const handleClose = (e) => {
    e.preventDefault();
    // refs.current = [];
    setrefresh(!refresh);
    setresponse({});
    setfilesError(0);
    setdbError(null);
    dispatch(
      getApplicationsSuccesInit(
        submittedApplication?.applicant ?? submittedApplication
      )
    );
    setShow(false);
  };
  const MB3 = 1048576;
  const MB30 = 25971520;
  const idRdbRcaFileHandler = (file) => {
    // const file = event.files[0];
    const parts = file?.name?.split(".");
    setidrdbrcacertificatetype(parts[parts.length - 1]);
    if (file !== null) {
      // console.log("NOT NULL");
    } else {
      // console.log("NULL");
    }
    if (!file?.name?.match(/\.(pdf|docx)$/)) {
      //alert("File type must be PDF");
    } else {
      if (file?.size > MB3) {
        alert("THE FILE IS TOO BIG");
      } else {
        setidrdbrcacertificate(file);
      }
    }
  };

  const landCertificateFileHandler = (event) => {
    const file = event.target.files[0];
    const parts = file?.name?.split(".");
    setlandcertificatetype(parts[parts.length - 1]);
    if (!file?.name?.match(/\.(pdf)$/)) {
      //alert("File type must be PDF");
    } else {
      if (file?.size > MB3) {
        alert("THE FILE IS TOO BIG");
      } else {
        setlandcertificate(event.target.files[0]);
      }
    }
  };

  const bankcertficateFileHandler = (event) => {
    const file = event.target.files[0];
    const parts = file?.name?.split(".");
    setbankcertficatetype(parts[parts.length - 1]);
    if (!file?.name?.match(/\.(pdf)$/)) {
      //alert("File type must be PDF");
    } else {
      if (file?.size > MB3) {
        alert("THE FILE IS TOO BIG");
      } else {
        setbankcertficate(event.target.files[0]);
      }
    }
  };

  const businessplanFileHandler = (event) => {
    const file = event.target.files[0];
    const parts = file?.name?.split(".");
    setbusinessplantype(parts[parts.length - 1]);
    if (file?.name?.match(/\.(mp4|mpeg4|mov|avi|WebM|FLV|WMV|AVCHD)$/)) {
      //alert("File type must be PDF");
    } else {
      if (file?.size > MB30) {
        alert("THE FILE IS TOO BIG");
      } else {
        setbusinessplan(event.target.files[0]);
      }
    }
  };

  const fileSelectorHandler = (event) => {
    const file = event.target.files[0];
    if (!file?.name?.match(/\.(pdf|docx)$/)) {
      //alert("File type must be PDF");
    } else {
      alert(file?.size);
    }
  };

  useEffect(() => {
    dispatch(getActiveCriterias());
    // if (theApplication === null && submitted === false) {
    //   history.push("/personal-details");
    // }
    if (oldApplicant !== null) {
      setisOldAppl(1);
      setreceivedGrant(oldApplicant?.receivedgrant);
    }

    const grantreq = Number(owncontribution.replace(/\,/g, ""));
    const totinvestment = Number(totalinvestment.replace(/\,/g, ""));
    const ownAtBank = Number(
      owncontributionasOnBankCertificate.replace(/\,/g, "")
    );
    const balanceTIandGrant = totinvestment - grantreq;
    setshowBankUpload(balanceTIandGrant > ownAtBank ? 0 : 1);

    if (submitted === true) {
      if (theApplication?.uuid !== undefined && theApplication?.uuid !== null) {
        // history.push("/last-page");
      }
    }
    if (filesError == 1) {
      dispatch(getApplicationsSuccesInit(submittedApplication?.applicant));
    }
    if (applicationError !== null && submitted === true) {
      setdbError(applicationError);
      dispatch(getApplicationsSuccesInit(submittedApplication?.applicant));
      //history.push("/application");
    } else if (
      applicationError === null &&
      (submitted === true) & (theApplication?.uuid !== undefined) &&
      theApplication?.uuid !== null
    ) {
      history.push("/last-page");
    }
  }, [
    theApplication,
    owncontributionasOnBankCertificate,
    refresh,
    applicationError,
    submitted,
    filesError,
  ]);

  const onPreview = (e) => {
    e.preventDefault();
    //  getApplicationCriteriasSuccesInit(criterias);
    const grantreq = Number(owncontribution.replace(/\,/g, ""));
    const totinvestment = Number(totalinvestment.replace(/\,/g, ""));
    const half = totinvestment / 2;
    if (
      owncontribution.trim() === "" ||
      totalinvestment.trim() === "" ||
      projectname.trim() === "" ||
      grantreq > totinvestment ||
      grantreq > 100000000 ||
      grantreq > half ||
      grantreq <= 0
    ) {
      setclicked(true);
    } else if (criterias.length !== objectsCriteria.length + 2) {
      errorNotificationDataMissing("Application");
      setclicked(true);
    } else {
      setclicked(false);
      setShow(true);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let resp = "true";
    if (resp === "true") {
      const objectToDB = {
        applicant: application,
        project: response?.project,
        activityTobeDone: response?.activityTobeDone,
        storageCapacity: response?.storageCapacity,
        dryerCapacity: response?.dryerCapacity,
        existingStorageCapacity: response?.existingStorageCapacity,
        existingDryerCapacity: response?.existingDryerCapacity,
        ownContributionAndBank: Number(
          owncontributionasOnBankCertificate?.replace(/\,/g, "")
        ),
        criterias: [],
      };
      setsubmittedApplication(objectToDB);
      const fd = new FormData();

      if (
        typeof idrdbrcacertificate?.name === "undefined" ||
        typeof landcertificate?.name === "undefined" ||
        typeof bankcertficate?.name === "undefined" ||
        typeof businessplan?.name === "undefined"
      ) {
        dispatch(getApplicationsSuccesInit(submittedApplication?.applicant));
        setfilesError(1);
      } else {
        setfilesError(0);
      }

      fd.append(
        "idrdbrcacertificate",
        idrdbrcacertificate,
        filename + "_ID_RDB_RCA." + idrdbrcacertificatetype
      );
      fd.append(
        "landcertificate",
        landcertificate,
        filename + "_Land_Certificate." + landcertificatetype
      );
      fd.append(
        "businessplan",
        businessplan,
        filename + "_Business_Plan." + businessplantype
      );
      fd.append(
        "bankcertficate",
        bankcertficate,
        filename + "_Bank_Certificate." + bankcertficatetype
      );
      fd.append("object", JSON.stringify(objectToDB));
      // dispatch(applicationInitialization())
      dispatch(createApplications(fd));
      setsubmitted(true);
      // setShow(false);
    } else {
      //errorNotificationDataMissing("Application");
      setclicked(true);
    }
  };

  const back = (e) => {
    e.preventDefault();
    dispatch(getApplicationsSuccesInit(application));
    history.push("/personal-details");
  };

  // USEREFFFF

  const addToRefs = (ref) => {
    if (ref && !refs.current.includes(ref)) {
      refs.current.push(ref);
    }
  };

  let responses = [];
  let data1 = null;
  let storageCapacity = null;
  let dryerCapacity = null;
  let isDryer = null;
  let isStorage = null;
  let baseStorageCapacity = null;
  let baseDryerCapacity = null;
  let hasStorage = null;
  let hasDryer = null;
  let existingStorageCapacity = null;
  let existingDryerCapacity = null;
  let project = "";
  let activityTobeDone = "";
  // let  ={};
  const testsubmission = (e) => {
    e.preventDefault();
    setsubmittedApplication(application);
    refs.current.forEach((element) => {
      if (Array.isArray(element.submit())) {
        setresponse(element.submit()[0]);
        storageCapacity = Number(
          element.submit()[0]?.storageCapacity.replace(/\,/g, "")
        );
        existingStorageCapacity = Number(
          element.submit()[0]?.existingStorageCapacity.replace(/\,/g, "")
        );
        baseStorageCapacity = element.submit()[0]?.baseStorageCapacity;
        isStorage = element.submit()[0]?.isStorage;
        hasStorage = element.submit()[0]?.hasStorage;
        dryerCapacity = Number(
          element.submit()[0]?.dryerCapacity.replace(/\,/g, "")
        );
        existingDryerCapacity = Number(
          element.submit()[0]?.existingDryerCapacity.replace(/\,/g, "")
        );
        baseDryerCapacity = element.submit()[0]?.baseDryerCapacity;
        isDryer = element.submit()[0]?.isDryer;
        hasDryer = element.submit()[0]?.hasDryer;
        project = element.submit()[0]?.project + "";
        activityTobeDone = element.submit()[0]?.activityTobeDone + "";
      } else {
        setresponse(element.submit());
        responses.push(element.submit());
      }
    });

    setthestorageCapacity(storageCapacity);
    setthedryerCapacity(dryerCapacity);
    settheexistingDryerCapacity(existingDryerCapacity);
    settheexistingStorageCapacity(existingStorageCapacity);
    settheproject(project);
    settheselectedactivity(activityTobeDone);
    setcriterias(responses);

    //  getApplicationCriteriasSuccesInit(criterias);
    const totinvestment = Number(totalinvestment.replace(/\,/g, ""));
    const half = totinvestment / 2;
    const grantreq = Number(owncontribution.replace(/\,/g, ""));
    let thirtyPercent = grantreq;
    const ownAtBank = Number(
      owncontributionasOnBankCertificate.replace(/\,/g, "")
    );
    const balanceTIandGrant = totinvestment - grantreq;
    setshowBankUpload(balanceTIandGrant > ownAtBank ? 0 : 1);
    if (isOldAppl === 1) {
      thirtyPercent = (totinvestment * 30) / 100;
      if (totinvestment > 260000000) {
        thirtyPercent = (260000000 * 30) / 100;
      }
    }
    if (
      owncontribution.trim() === "" ||
      totalinvestment.trim() === "" ||
      projectname.trim() === "" ||
      grantreq > totinvestment ||
      grantreq > 130000000 ||
      grantreq > thirtyPercent ||
      grantreq > half ||
      grantreq <= 0 ||
      balanceTIandGrant > ownAtBank
    ) {
      setclicked(true);
      //  console.log("OBJECT INITIATION: ", response);
      // setrefresh(!refresh);
    } else if (project?.length <= 0 || activityTobeDone?.length <= 0) {
      setclicked(true);
    } else if (
      hasStorage === 0 &&
      isDryer === 0 &&
      baseStorageCapacity > storageCapacity
    ) {
      // errorNotificationDataMissing("Application");
      setclicked(true);
    } else if (
      hasStorage !== 0 &&
      isDryer === 0 &&
      existingStorageCapacity < baseStorageCapacity
    ) {
      setclicked(true);
      // setrefresh(!refresh);
    } else if (
      hasDryer === 0 &&
      isDryer !== 0 &&
      baseDryerCapacity > dryerCapacity
    ) {
      // errorNotificationDataMissing("Application"); && hasStorage!==0
      setclicked(true);
    } else if (
      hasDryer === 0 &&
      hasStorage !== 0 &&
      isDryer !== 0 &&
      isStorage !== 0 &&
      baseStorageCapacity > storageCapacity
    ) {
      setclicked(true);
    } else if (hasDryer !== 0 && existingDryerCapacity < baseDryerCapacity) {
      setclicked(true);
    } else if (!idrdbrcacertificate instanceof Blob) {
      errorNotificationDataMissing("Application");
      setclicked(true);
      //setrefresh(!refresh);
    } else {
      setclicked(false); //idrdbrcacertificatetype
      setShow(true);
    }
  };

  return (
    <>
      <Header />
      <ReactNotifications />
      <div className="container">
        <Modal show={show} onHide={handleClose} size="xl">
          <Modal.Body>
            {applicationLoading ? (
              <>
                <div className="w-100 text-center">
                  <MiSpinner />
                </div>
              </>
            ) : (
              <>
                <div className="row px-5 pt-2 pb-2">
                  <h6 className="text-primary text-center pb-3">
                    Umwirondoro/Application Details
                  </h6>
                  <div className="col-sm-12 col-md-4">
                    <ObjectDetails
                      appltype={application?.applicanttype}
                      title={"Amazina/Names"}
                      value={
                        application?.firstname + " " + application?.lastname
                      }
                    />
                    <ObjectDetails
                      title={"Project Name/Umushinga"}
                      value={application?.projectname}
                    />
                    <ObjectDetails
                      title={"Total Investment/Igishoro cyose"}
                      value={formatDecimals(application?.totalinvestment)}
                    />
                    <ObjectDetails
                      title={"Uruhare rwa nyiri umushinga"}
                      value={formatDecimals(owncontributionasOnBankCertificate)}
                    />
                    <ObjectDetails
                      title={"Desired Grant/Inkunga ukeneye"}
                      value={formatDecimals(application?.owncontribution)}
                    />
                    <div
                      className={
                        application?.applicanttype !== "individual"
                          ? "d-show"
                          : "d-none"
                      }
                    >
                      <ObjectDetails
                        title={"Company Name"}
                        value={application?.companyname}
                      />
                      <ObjectDetails
                        title={"TIN/RCA Index"}
                        value={application?.tin}
                      />
                    </div>
                    <div
                      className={
                        application?.applicanttype === "individual"
                          ? "d-show"
                          : "d-none"
                      }
                    >
                      <ObjectDetails
                        title={"Igitsina/Gender"}
                        value={application?.gender}
                      />
                      <ObjectDetails
                        title={"Irangamuntu/National ID"}
                        value={application?.nationalId}
                      />
                      <ObjectDetails
                        title={"Date of birth/Italiki y'amavuko"}
                        value={application?.dob}
                      />
                    </div>
                    <ObjectDetails
                      title={"Telefoni/Telephone"}
                      value={application?.telephone}
                    />
                    <ObjectDetails
                      title={"Imeyiri/Email"}
                      value={application?.email}
                    />

                    <div className="pt-2">
                      <span>Applicant Location/Aho usaba abarizwa</span>
                      <ObjectDetails
                        title={"Intara/Province"}
                        value={application?.province}
                      />
                      <ObjectDetails
                        title={"Akarere/District"}
                        value={application?.district}
                      />
                      <ObjectDetails
                        title={"Sector/Umurenge"}
                        value={application?.sector}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-8">
                    <div className="pt-2 pb-2">
                      <span>Project location/ Aho umushinga ubarizwa</span>
                      <ObjectDetails
                        title={"Akarere/District"}
                        value={application?.projprovince}
                      />
                      <ObjectDetails
                        title={"Akarere/District"}
                        value={application?.projdistrict}
                      />
                      <ObjectDetails
                        title={"Sector/Umurenge"}
                        value={application?.projsector}
                      />
                      <span>Amakuru y'umushinga</span>
                    </div>
                    <ObjectDetails
                      title={"Igihingwa"}
                      value={response?.project}
                    />
                    <ObjectDetails
                      title={"Ikizakorwa(Ikizagurwa/Ikizakorwa)"}
                      value={response?.activityTobeDone}
                    />
                    <span
                      className={thestorageCapacity !== 0 ? "d-show" : "d-none"}
                    >
                      <ObjectDetails
                        title={"Ingano y'ububiko (toni) ukeneye"}
                        value={formatDecimals(thestorageCapacity + "")}
                      />
                    </span>
                    <span
                      className={
                        theexistingStorageCapacity !== 0 ? "d-show" : "d-none"
                      }
                    >
                      <ObjectDetails
                        title={"Ingano y'ububiko (toni) ufite"}
                        value={formatDecimals(theexistingStorageCapacity + "")}
                      />
                    </span>
                    <span
                      className={thedryerCapacity !== 0 ? "d-show" : "d-none"}
                    >
                      <ObjectDetails
                        title={"Imbaraga z'imashini (MT/hr) Ukeneye"}
                        value={formatDecimals(thedryerCapacity + "")}
                      />
                    </span>
                    <span
                      className={
                        theexistingDryerCapacity !== 0 ? "d-show" : "d-none"
                      }
                    >
                      <ObjectDetails
                        title={"Imbaraga z'imashini yumisha ufite (Mt/hr)"}
                        value={formatDecimals(theexistingDryerCapacity + "")}
                      />
                    </span>
                    <div className="mb-3"></div>
                  </div>
                </div>
                <div className="text-center pb-5">
                  <span className={filesError === 1 ? "text-danger" : "d-none"}>
                    One or more files need to be attached
                  </span>
                  <span className={dbError !== null ? "text-danger" : "d-none"}>
                    Check values indicated with no data ({dbError})
                  </span>
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-primary text-white"
                    onClick={handleClose}
                  >
                    Hindura/Edit
                  </button>{" "}
                  <span className="px-3"></span>
                  <button
                    className="btn btn-success text-white"
                    onClick={onSubmit}
                  >
                    {"Ohereza/Submit"}
                  </button>
                </div>
              </>
            )}
          </Modal.Body>
        </Modal>
        <Beadcrumb />
        <div className="pt-5">
          <PageTitle pagetitle={WORKSPACE} />
        </div>
        <div className="pt-2 pb-2 col-md-12 text-center">
          <ApplicantsDetails
            title={"Amazina/Names"}
            value={theApplication?.firstname + " " + theApplication?.lastname}
          />
          <ApplicantsDetails
            title={"ID/TIN"}
            value={theApplication?.nationalId ?? theApplication?.tin}
          />
          <ApplicantsDetails title={"Tel"} value={theApplication?.telephone} />
          <ApplicantsDetails title={"Email"} value={theApplication?.email} />
        </div>
        <div id="contact" className="input-data">
          <div className="" data-aos="fade-up">
            <div className="row d-flex php-email-form">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-sm-10 col-md-4">
                    <div className="form-group py-1">
                      <InputText2
                        title={"Project name/Inyito y’umushinga"}
                        value={projectname}
                        name="projectname"
                        placeholder={"Project name/Inyito y’umushinga"}
                        isClicked={clicked}
                        onChange={(e) => {
                          setprojectname(e.target.value);
                          setclicked(false);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-10 col-md-4">
                    <div className="form-group py-1">
                      <InputText2
                        inptype="number"
                        title={"Total investment/Ingano y’igishoro (Frw)"}
                        value={totalinvestment}
                        name="totalinvestment"
                        placeholder={"Total investment/Ingano y’igishoro"}
                        isClicked={clicked}
                        onChange={(e) => {
                          settotalinvestment(e.target.value);
                          setclicked(false);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-10 col-md-4">
                    <div className="form-group py-1">
                      <InputText2
                        inpvalue="grant"
                        inptype="number"
                        isOldAppl={isOldAppl}
                        receivedGrant={receivedGrant}
                        title={"Desired Grant/Inkunga ukeneye (Frw)"}
                        value={owncontribution}
                        totalinvestment={totalinvestment}
                        name="owncontribution"
                        placeholder={"Desired Grant/Inkunga ukeneye"}
                        isClicked={clicked}
                        onChange={(e) => {
                          setowncontribution(e.target.value);
                          setclicked(false);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row d-flex">
                  <label className="pt-2 bdf-bluefontcolor col-md-3">
                    PROJECT LOCATION / AHO UMUSHINGA UBARIZWA
                  </label>
                  <div className="row col-md-9">
                    <div className="col-md-4">
                      <SelectInput
                        title="Province/Intara"
                        startingValue={"HITAMO/SELECT"}
                        options={arrayToOptionsHandler(Provinces())}
                        value={projprovince}
                        changed={setTheProvince}
                        isClicked={clicked}
                      />
                    </div>
                    <div className="col-md-4">
                      <SelectInput
                        title="District/Akarere"
                        startingValue={"HITAMO/SELECT"}
                        options={districts}
                        value={projdistrict}
                        changed={setTheDistrict}
                        isClicked={clicked}
                      />
                    </div>
                    <div className="col-md-4">
                      <SelectInput
                        title="Sector/Umurenge"
                        startingValue={"HITAMO/SELECT"}
                        options={sectors}
                        value={projsector}
                        changed={setprojSector}
                        isClicked={clicked}
                      />
                    </div>
                  </div>
                </div>
                <div className="pt-3">
                  <div className="row col-md-12">
                    <div className="col-sm-10 col-md-5">
                      <div className="form-group py-1">
                        <UplodadFile
                          title="RDB or RCA Certificate (National ID for individuals) 2MB"
                          name="idrdbrcacertificate"
                          required
                          onChange={(e) =>
                            idRdbRcaFileHandler(e.target.files[0])
                          }
                          // onChecking={a}
                        />
                      </div>
                    </div>
                    <div className="col-sm-10 col-md-5">
                      <div className="form-group py-1">
                        <UplodadFile
                          title="Land Certificate (2MB)"
                          name="landcertificate"
                          required
                          onChange={landCertificateFileHandler}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row col-md-12">
                    <div className="col-sm-10 col-md-5">
                      <div className="form-group py-1">
                        <InputText2
                          inpvalue="bankstatement"
                          inptype="number"
                          isOldAppl={isOldAppl}
                          receivedGrant={receivedGrant}
                          requestedGrant={owncontribution}
                          title={"Uruhare rwawe k'umushinga"}
                          value={owncontributionasOnBankCertificate}
                          totalinvestment={totalinvestment}
                          name="owncontributionasOnBankCertificate"
                          placeholder={"Uruhare rwawe"}
                          isClicked={clicked}
                          onChange={(e) => {
                            setowncontributionasOnBankCertificate(
                              e.target.value
                            );
                            setclicked(false);
                          }}
                        />
                      </div>
                    </div>
                    <div className={"col-sm-10 col-md-5"}>
                      <div className="form-group py-1">
                        <UplodadFile
                          title="Urwandiko rwa Banki (2MB)"
                          name="bankcertficate"
                          required
                          onChange={bankcertficateFileHandler}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row d-flex pt-2">
                  {objectsCriteriaLoading ? (
                    <>
                      <div className="w-100 text-center">
                        <MiSpinner />
                      </div>
                    </>
                  ) : (
                    <>
                      {objectsCriteria?.map((object, index) => (
                        <div
                          className={
                            object?.haschildren === "true"
                              ? "col-sm-10 col-md-12"
                              : "col-sm-10 col-md-6"
                          }
                          key={index}
                        >
                          <div className="form-group py-1 col-md-12">
                            <SelectInputCriteria2nd
                              ref={addToRefs}
                              key={index}
                              id={index}
                              title={object?.criteria}
                              haschildren={object?.haschildren}
                              startingValue={"HITAMO/SELECT"}
                              options={object?.criteriaValues}
                              value={valuess[index + 1]}
                              childvalue={childvalues[index + 1]}
                              changed={() => {}}
                              storeboxerror={storeboxerror}
                              dryerboxerror={dryerboxerror}
                              childchanged={() => {}}
                              criteriaId={object?.id}
                              criteria={object?.criteria}
                              isClicked={clicked}
                              subcriteria={[]}
                              subcriteriass={object}
                              subcriteriassi={
                                object.criteriaValues[index]?.subCriteriaValues
                              }
                              criteriaTitle={thecriteriaTitle}
                            />
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
                <div>
                  <div className="col-sm-10 col-md-5">
                    <div className="form-group py-1">
                      <UplodadFile
                        title="Business Plan (20MB)"
                        name="businessplan"
                        required
                        onChange={businessplanFileHandler}
                      />
                    </div>
                  </div>
                </div>
                <div className="pt-2">
                  <div className="row gx-5"></div>
                  <SubmitButton action={"Back/Subira Inyuma"} onSubmit={back} />
                  <span className="px-2"></span>
                  <SubmitButton
                    classes="bg-success"
                    action={"Continue/Komeza"}
                    onSubmit={testsubmission}
                  />
                </div>
              </div>
              <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CriteriasPageV2;
