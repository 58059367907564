import axios from "../../axios-base";

export const GET_CRITERIAS_SUCCESS = "GET_CRITERIAS_SUCCESS";
export const GET_ONE_CRITERIAS_SUCCESS = "GET_ONE_CRITERIAS_SUCCESS";
export const GET_CRITERIAS_START = "GET_CRITERIAS_START";
export const GET_CRITERIAS_FAIL = "GET_CRITERIAS_FAIL";

export const GET_CRITERIA_VALUES_SUCCESS = "GET_CRITERIA_VALUES_SUCCESS";
export const GET_ONE_CRITERIA_VALUES_SUCCESS = "GET_ONE_CRITERIA_VALUES_SUCCESS";
export const GET_CRITERIA_VALUES_START = "GET_CRITERIA_VALUES_START";
export const GET_CRITERIA_VALUES_FAIL = "GET_CRITERIA_VALUES_FAIL";

export const GET_CRITERIA_VALUES_SUBS_SUCCESS = "GET_CRITERIA_VALUES_SUBS_SUCCESS";
export const GET_ONE_CRITERIA_VALUES_SUBS_SUCCESS = "GET_ONE_CRITERIA_VALUES_SUBS_SUCCESS";
export const GET_CRITERIA_VALUES_SUBS_START = "GET_CRITERIA_VALUES_SUBS_START";
export const GET_CRITERIA_VALUES_SUBS_FAIL = "GET_CRITERIA_VALUES_SUBS_FAIL";

export const getCriteriasSuccess = (data) => {
  return {
    type: GET_CRITERIAS_SUCCESS,
    payload: data,
  };
};

export const getOneCriteriasSuccess = (data) => {
  return {
    type: GET_ONE_CRITERIAS_SUCCESS,
    payload: data,
  };
};

export const getCriteriasStart = () => {
  return {
    type: GET_CRITERIAS_START,
  };
};
export const getCriteriasFail = (error) => {
  return {
    type: GET_CRITERIAS_FAIL,
    payload: error,
  };
};

export const getActiveCriterias = () => {
  return (dispatch) => {
    dispatch(getCriteriasStart());
    axios
      .get(`/criterias/active`)
      .then((response) => {
        dispatch(getCriteriasSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getCriteriasFail(err));
      });
  };
};

export const createCriterias = (object, token) => {
  return (dispatch) => {
    dispatch(getCriteriasStart());
    axios
      .post(`/criterias/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneCriteriasSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getCriteriasFail(err));
      });
  };
};

export const getCriterias = () => {
  return (dispatch) => {
    dispatch(getCriteriasStart());
    axios
      .get(`/criterias`)
      .then((response) => {
        dispatch(getCriteriasSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getCriteriasFail(err));
      });
  };
};

export const getOneCriterias = (token, id) => {
  return (dispatch) => {
    dispatch(getCriteriasStart());
    axios
      .get(`/criterias/find/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneCriteriasSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getCriteriasFail(err));
      });
  };
};

export const activatingCriterias = (token, id) => {
  return (dispatch) => {
    dispatch(getCriteriasStart());
    axios
      .put(`/criterias/activating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneCriteriasSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getCriteriasFail(err));
      });
  };
};

export const desActivatingCriterias = (token, id) => {
  return (dispatch) => {
    dispatch(getCriteriasStart());
    axios
      .put(`/criterias/desactivating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneCriteriasSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getCriteriasFail(err));
      });
  };
};

export const updateCriterias = (token, object) => {
  return (dispatch) => {
    dispatch(getCriteriasStart());
    axios
      .put(`/criterias/edit/${object?.id}`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneCriteriasSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getCriteriasFail(err));
      });
  };
};

export const getCriteriaValuesSuccess = (data) => {
  return {
    type: GET_CRITERIA_VALUES_SUCCESS,
    payload: data,
  };
};

export const getOneCriteriaValuesSuccess = (data) => {
  return {
    type: GET_ONE_CRITERIA_VALUES_SUCCESS,
    payload: data,
  };
};

export const getCriteriaValuesStart = () => {
  return {
    type: GET_CRITERIA_VALUES_START,
  };
};
export const getCriteriaValuesFail = (error) => {
  return {
    type: GET_CRITERIA_VALUES_FAIL,
    payload: error,
  };
};

export const getActiveCriteriaValues = () => {
  return (dispatch) => {
    dispatch(getCriteriaValuesStart());
    axios
      .get(`/criteria-values/active`)
      .then((response) => {
        dispatch(getCriteriaValuesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getCriteriaValuesFail(err));
      });
  };
};

export const createCriteriaValues = (object, token) => {
  return (dispatch) => {
    dispatch(getCriteriaValuesStart());
    axios
      .post(`/criteria-values/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneCriteriaValuesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getCriteriaValuesFail(err));
      });
  };
};

export const getCriteriaValues = (id) => {
  return (dispatch) => {
    dispatch(getCriteriaValuesStart());
    axios
      .get(`/criteria-values/criteria/${id}`)
      .then((response) => {
        dispatch(getCriteriaValuesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getCriteriaValuesFail(err));
      });
  };
};

export const getOneCriteriaValues = (token, id) => {
  return (dispatch) => {
    dispatch(getCriteriaValuesStart());
    axios
      .get(`/criteria-values/find/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneCriteriaValuesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getCriteriaValuesFail(err));
      });
  };
};

export const activatingCriteriaValues = (token, id) => {
  return (dispatch) => {
    dispatch(getCriteriaValuesStart());
    axios
      .put(`/criteria-values/activating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneCriteriaValuesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getCriteriaValuesFail(err));
      });
  };
};

export const desActivatingCriteriaValues = (token, id) => {
  return (dispatch) => {
    dispatch(getCriteriaValuesStart());
    axios
      .put(`/criteria-values/desactivating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneCriteriaValuesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getCriteriaValuesFail(err));
      });
  };
};

export const updateCriteriaValues = (token, object) => {
  return (dispatch) => {
    dispatch(getCriteriaValuesStart());
    axios
      .put(`/criteria-values/edit/${object?.id}`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneCriteriaValuesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getCriteriaValuesFail(err));
      });
  };
};

export const getCriteriaValuesSubsSuccess = (data) => {
  return {
    type: GET_CRITERIA_VALUES_SUBS_SUCCESS,
    payload: data,
  };
};

export const getOneCriteriaValuesSubsSuccess = (data) => {
  return {
    type: GET_ONE_CRITERIA_VALUES_SUBS_SUCCESS,
    payload: data,
  };
};

export const getCriteriaValuesSubsStart = () => {
  return {
    type: GET_CRITERIA_VALUES_SUBS_START,
  };
};
export const getCriteriaValuesSubsFail = (error) => {
  return {
    type: GET_CRITERIA_VALUES_SUBS_FAIL,
    payload: error,
  };
};

export const getActiveCriteriaValuesSubs = () => {
  return (dispatch) => {
    dispatch(getCriteriaValuesSubsStart());
    axios
      .get(`/criteria-values-subs/active`)
      .then((response) => {
        dispatch(getCriteriaValuesSubsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getCriteriaValuesSubsFail(err));
      });
  };
};

export const createCriteriaValuesSubs = (object, token) => {
  return (dispatch) => {
    dispatch(getCriteriaValuesSubsStart());
    axios
      .post(`/criteria-values-subs/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneCriteriaValuesSubsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getCriteriaValuesSubsFail(err));
      });
  };
};

export const getCriteriaValuesSubs = (id) => {
  return (dispatch) => {
    dispatch(getCriteriaValuesSubsStart());
    axios
      .get(`/criteria-values-subs/criteria/${id}`)
      .then((response) => {
        dispatch(getCriteriaValuesSubsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getCriteriaValuesSubsFail(err));
      });
  };
};

export const getOneCriteriaValuesSubs = (token, id) => {
  return (dispatch) => {
    dispatch(getCriteriaValuesSubsStart());
    axios
      .get(`/criteria-values-subs/find/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneCriteriaValuesSubsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getCriteriaValuesSubsFail(err));
      });
  };
};

export const activatingCriteriaValuesSubs = (token, id) => {
  return (dispatch) => {
    dispatch(getCriteriaValuesSubsStart());
    axios
      .put(`/criteria-values-subs/activating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneCriteriaValuesSubsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getCriteriaValuesSubsFail(err));
      });
  };
};

export const desActivatingCriteriaValuesSubs = (token, id) => {
  return (dispatch) => {
    dispatch(getCriteriaValuesSubsStart());
    axios
      .put(`/criteria-values-subs/desactivating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneCriteriaValuesSubsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getCriteriaValuesSubsFail(err));
      });
  };
};

export const updateCriteriaValuesSubs = (token, object) => {
  return (dispatch) => {
    dispatch(getCriteriaValuesSubsStart());
    axios
      .put(`/criteria-values-subs/edit/${object?.id}`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneCriteriaValuesSubsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getCriteriaValuesSubsFail(err));
      });
  };
};

