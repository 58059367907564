import { useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
const Header = () => {
  const history = useHistory();

  useEffect(() => {}, []);

  return (
    <header
      id="header"
      className="header d-flex align-items-center fixed-top bg-bdf-blue"
    >
      <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
        <nav id="navbar" className={"navbar text-center d-flex justify-end"}>
          <ul className="text-white ">
            <li className="">CDAT Application Portal</li>
          </ul>
          <div className="flex-column d-none">
            <ul>
              <li>
                <NavLink to={"/"} className="">
                  Ahabanza / Home
                </NavLink>
              </li>
              <li>
                <NavLink to={"/terms-and-conditions"} className="">
                 Amabwiriza / Terms and Conditions
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
